import {
  LumBuildupType,
  LumBuildupTypeSlug,
  LumBusinessCaseSummaryBuildupEntry,
  LumBusinessCaseSummaryBuildupEntryProduct,
  LumBusinessRelationRole,
  LumClientBuildupTree,
  LumClientBuildupTreeProduct,
} from '@lum-types';
import { neverError } from '../never.helper';

export function getLabelByBuildupProductProperty(
  key:
    | keyof LumBusinessCaseSummaryBuildupEntryProduct
    | keyof LumClientBuildupTreeProduct
): string {
  switch (key) {
    case 'id':
      return 'ID';

    case 'productCategory':
      return 'lum.business-case.detail.data-view.header.productCategory';

    case 'customerProductId':
    case 'supplierProductId':
    case 'clientProductId':
      return 'lum.business-case.detail.data-view.header.my-productId';

    case 'customerProductDetails':
    case 'supplierProductDetails':
    case 'clientProductDetails':
      return 'lum.business-case.detail.data-view.header.my-productDescription';

    case 'platformProductId':
      return 'lum.business-case.detail.data-view.header.product-id';

    case 'platformProductDetails':
      return 'lum.business-case.detail.data-view.header.product-description';

    case 'alternativePlatformProductId':
      return 'Alternative Platform Product ID';

    default:
      throw neverError(key);
  }
}

export function convertBuildupEntryToBuildupTree(
  buildupEntry: LumBusinessCaseSummaryBuildupEntry | null,
  businessRelationRole: LumBusinessRelationRole
): LumClientBuildupTree | undefined {
  if (!buildupEntry?.buildupProducts.length) {
    return undefined;
  }

  const products: LumClientBuildupTreeProduct[] =
    buildupEntry.buildupProducts.map(({ product }) => {
      return {
        productCategory: product.productCategory,
        clientProductId:
          businessRelationRole === 'CUSTOMER'
            ? product.customerProductId
            : product.supplierProductId,
        clientProductDetails:
          businessRelationRole === 'CUSTOMER'
            ? product.customerProductDetails
            : product.supplierProductDetails,
        platformProductId: product.platformProductId!,
        platformProductDetails: product.platformProductDetails,
      };
    });

  const buildupTree: LumClientBuildupTree = {
    clientBuildupId:
      businessRelationRole === 'CUSTOMER'
        ? buildupEntry.customerBuildupId!
        : buildupEntry.supplierBuildupId!,
    buildupType: buildupEntry.buildupType,
    products,
  };

  return buildupTree;
}

export function getSlugByBuildupType(
  buildupType: LumBuildupType
): LumBuildupTypeSlug {
  switch (buildupType) {
    case 'COMPONENT':
      return 'component';

    case 'ISO':
      return 'iso';

    default:
      throw neverError(buildupType);
  }
}

export function getBuildupTypeBySlug(
  buildupTypeSlug?: LumBuildupTypeSlug
): LumBuildupType | null {
  if (!buildupTypeSlug) {
    return null;
  }

  switch (buildupTypeSlug) {
    case 'component':
      return 'COMPONENT';

    case 'iso':
      return 'ISO';

    default:
      throw neverError(buildupTypeSlug);
  }
}

export function getTranslationKeyByBuildupType(
  buildupType: LumBuildupType
): string {
  switch (buildupType) {
    case 'COMPONENT':
      return 'lum.buildups.buildup-type.component';

    case 'ISO':
      return 'lum.buildups.buildup-type.iso';

    default:
      return '';
    // throw neverError(buildupType); // TODO revert to this line after all types are handled
  }
}
