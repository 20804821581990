import { LumUserRole } from '@lum-types';
import { neverError } from '../never.helper';

export function convertUserRoleToLocoKey(userRole: LumUserRole): string {
  switch (userRole) {
    case 'PLATFORM_ADMIN':
      return 'lum.user.role.platform-admin';

    case 'COMPANY_OWNER':
      return 'lum.user.role.company-owner';

    default:
      throw neverError(userRole);
  }
}
