import { LumLogger } from './logger.utils';

export type LumStorageType =
  | string
  | LumDataViewPagination
  | 'lum.data-source'
  | 'lum.last-route'
  | 'lum.is-navigation-expanded'
  | 'lum.language';

export type LumDataViewPagination =
  | 'lum.alternative-products.pageSize'
  | 'lum.buildup-assignment.pageSize'
  | 'lum.buildups.pageSize'
  | 'lum.business-case.pageSize'
  | 'lum.business-case.summary.pageSize'
  | 'lum.business-relations.pageSize'
  | 'lum.open-tasks'
  | 'lum.processing.pageSize'
  | 'lum.product.pageSize'
  | 'lum.user-account.team.pageSize';

export abstract class StorageUtils {
  public static set<T>(
    key: LumStorageType,
    value?: T,
    storage: Storage = window.localStorage
  ): void {
    if (value === undefined) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, JSON.stringify(value));
    }
  }

  public static get<T>(
    key: LumStorageType,
    storage: Storage = window.localStorage
  ): T | undefined {
    const value = storage.getItem(key);
    if (value != null) {
      try {
        return JSON.parse(value) as T;
      } catch (e) {
        LumLogger.warn(`Loading storage value failed for key: ${key}`, e);
        return undefined;
      }
    }
    return undefined;
  }
}
