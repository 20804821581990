import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumDataSource, LumUser } from '@lum-types';
import { StorageUtils } from '@lum-utils';

import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataService } from '../base-data/base-data.abstract';
import { UserAPIService } from './user.api.service';
import { UserMockService } from './user.mock.service';

export abstract class UserService extends BaseDataService<LumUser> {
  public abstract getCurrentUser(): Observable<LumUser>;
}

export function UserFactory(apiService: ApiService, dcuplService: DcuplService): UserService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new UserMockService();
  }

  return new UserAPIService(apiService, dcuplService);
}

export const LUM_USER_PROVIDER: FactoryProvider = {
  provide: UserService,
  useFactory: UserFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
