/* eslint-disable sonarjs/max-switch-cases */
import {
  LumBusinessCaseState,
  LumBusinessCaseStateLevel,
  LumIcon,
} from '@lum-types';
import { neverError } from '../never.helper';

export function convertBusinessCaseStateToLocoKey(
  businessCaseState: LumBusinessCaseState
): string {
  switch (businessCaseState) {
    case 'CREATED':
      return 'lum.business-case.state.created.title';

    case 'ASSIGNMENT_FAILURE_CUSTOMER':
      return 'lum.business-case.state.assignment-failure-customer.title';

    case 'AVAILABLE_FOR_SUPPLIER':
      return 'lum.business-case.state.available-for-supplier.title';

    case 'ROUTED':
      return 'lum.business-case.state.routed.title';

    case 'ASSIGNED_TO_PLATFORM':
      return 'lum.business-case.state.assigned-to-platform.title';

    case 'ASSIGNED_TO_SUPPLIER':
      return 'lum.business-case.state.assigned-to-supplier.title';

    case 'SUPPLIER_CONNECTOR_DISABLED':
      return 'lum.business-case.state.supplier-connector-disabled.title';

    case 'ROUTING_FAILURE':
      return 'lum.business-case.state.routing-failure.title';

    case 'ASSIGNMENT_FAILURE_SUPPLIER':
      return 'lum.business-case.state.assignment-failure-supplier.title';

    case 'CUSTOMER_CONVERSION_FAILURE':
      return 'lum.business-case.state.customer-conversion-failure.title';

    case 'SUPPLIER_RECEIVING_FAILURE':
      return 'lum.business-case.state.supplier-receiving-failure.title';

    case 'FILE_DOWNLOAD_FAILURE':
      return 'lum.business-case.state.file-download-failure.title';

    case 'FILE_UPLOAD_FAILURE':
      return 'lum.business-case.state.file-upload-failure.title';

    case 'CUSTOMER_ORDER_RECEIVED':
      return 'lum.business-case.state.customer-order-received.title';

    case 'FORWARDED_TO_SUPPLIER':
      return 'lum.business-case.state.forwarded-to-supplier.title';

    case 'CUSTOMER_ORDER_IMPORT_FAILED':
      return 'lum.business-case.state.customer-order-import-failed.title';

    case 'BUSINESS_RELATION_PENDING':
      return 'lum.business-case.state.business-relation-pending.title';

    case 'BUSINESS_RELATION_SUSPENDED':
      return 'lum.business-case.state.business-relation-suspended.title';

    case 'CUSTOMER_REQUEST_RECEIVED':
      return 'lum.business-case.state.customer-request-received.title';

    case 'CUSTOMER_REQUEST_MISSING_INFORMATION':
      return 'lum.business-case.state.customer-request-missing-information.title';

    case 'CUSTOMER_REQUEST_REJECTED':
      return 'lum.business-case.state.customer-request-rejected.title';

    case 'CUSTOMER_QUOTATION_SENT':
      return 'lum.business-case.state.customer-quotation-sent.title';

    case 'CUSTOMER_QUOTATION_ACCEPTED':
      return 'lum.business-case.state.customer-quotation-accepted.title';

    case 'CUSTOMER_QUOTATION_REJECTED':
      return 'lum.business-case.state.customer-quotation-rejected.title';

    case 'CUSTOMER_ORDER_MISSING_INFORMATION':
      return 'lum.business-case.state.customer-order-missing-information.title';

    case 'CUSTOMER_ORDER_REJECTED':
      return 'lum.business-case.state.customer-order-rejected.title';

    case 'CUSTOMER_ORDER_ACCEPTED':
      return 'lum.business-case.state.customer-order-accepted.title';

    case 'CUSTOMER_ORDER_PRODUCTION_PLANNED':
      return 'lum.business-case.state.customer-order-production-planned.title';

    case 'CUSTOMER_ORDER_IN_PRODUCTION':
      return 'lum.business-case.state.customer-order-in-production.title';

    case 'CUSTOMER_ORDER_PREPARING_FOR_SHIPMENT':
      return 'lum.business-case.state.customer-order-preparing-for-shipment.title';

    case 'CUSTOMER_ORDER_IN_DELIVERY':
      return 'lum.business-case.state.customer-order-in-delivery.title';

    case 'CUSTOMER_ORDER_DELIVERED':
      return 'lum.business-case.state.customer-order-delivered.title';

    case 'CUSTOMER_ORDER_FINISHED':
      return 'lum.business-case.state.customer-order-finished.title';

    case 'CUSTOMER_ORDER_CANCELLATION_RECEIVED':
      return 'lum.business-case.state.customer-order-cancellation-received.title';

    case 'CUSTOMER_ORDER_CANCELLATION_ACCEPTED':
      return 'lum.business-case.state.customer-order-cancellation-accepted.title';

    case 'CUSTOMER_ORDER_CANCELLATION_REJECTED':
      return 'lum.business-case.state.customer-order-cancellation-rejected.title';

    default:
      throw neverError(businessCaseState);
  }
}

export function convertBusinessCaseStateToDescriptionLocoKey(
  businessCaseState: LumBusinessCaseState
): string {
  switch (businessCaseState) {
    case 'ASSIGNMENT_FAILURE_CUSTOMER':
      return 'lum.business-case.state.assignment-failure-customer.description';

    case 'SUPPLIER_CONNECTOR_DISABLED':
      return 'lum.business-case.state.supplier-connector-disabled.description';

    case 'ROUTING_FAILURE':
      return 'lum.business-case.state.routing-failure.description';

    case 'ASSIGNMENT_FAILURE_SUPPLIER':
      return 'lum.business-case.state.assignment-failure-supplier.description';

    case 'CUSTOMER_CONVERSION_FAILURE':
      return 'lum.business-case.state.customer-conversion-failure.description';

    case 'SUPPLIER_RECEIVING_FAILURE':
      return 'lum.business-case.state.supplier-receiving-failure.description';

    case 'FILE_DOWNLOAD_FAILURE':
      return 'lum.business-case.state.file-download-failure.description';

    case 'FILE_UPLOAD_FAILURE':
      return 'lum.business-case.state.file-upload-failure.description';

    case 'AVAILABLE_FOR_SUPPLIER':
      return 'lum.business-case.state.available-for-supplier.description';

    case 'CREATED':
      return 'lum.business-case.state.created.description';

    case 'ASSIGNED_TO_SUPPLIER':
      return 'lum.business-case.state.assigned-to-supplier.description';

    case 'ASSIGNED_TO_PLATFORM':
      return 'lum.business-case.state.assigned-to-platform.description';

    case 'ROUTED':
      return 'lum.business-case.state.routed.description';

    case 'CUSTOMER_ORDER_RECEIVED':
      return 'lum.business-case.state.customer-order-received.description';

    case 'FORWARDED_TO_SUPPLIER':
      return 'lum.business-case.state.forwarded-to-supplier.description';

    case 'CUSTOMER_ORDER_IMPORT_FAILED':
      return 'lum.business-case.state.customer-order-import-failed.description';

    case 'BUSINESS_RELATION_PENDING':
      return 'lum.business-case.state.business-relation-pending.description';

    case 'BUSINESS_RELATION_SUSPENDED':
      return 'lum.business-case.state.business-relation-suspended.description';

    case 'CUSTOMER_REQUEST_RECEIVED':
      return 'lum.business-case.state.customer-request-received.description';

    case 'CUSTOMER_REQUEST_MISSING_INFORMATION':
      return 'lum.business-case.state.customer-request-missing-information.description';

    case 'CUSTOMER_REQUEST_REJECTED':
      return 'lum.business-case.state.customer-request-rejected.description';

    case 'CUSTOMER_QUOTATION_SENT':
      return 'lum.business-case.state.customer-quotation-sent.description';

    case 'CUSTOMER_QUOTATION_ACCEPTED':
      return 'lum.business-case.state.customer-quotation-accepted.description';

    case 'CUSTOMER_QUOTATION_REJECTED':
      return 'lum.business-case.state.customer-quotation-rejected.description';

    case 'CUSTOMER_ORDER_MISSING_INFORMATION':
      return 'lum.business-case.state.customer-order-missing-information.description';

    case 'CUSTOMER_ORDER_REJECTED':
      return 'lum.business-case.state.customer-order-rejected.description';

    case 'CUSTOMER_ORDER_ACCEPTED':
      return 'lum.business-case.state.customer-order-accepted.description';

    case 'CUSTOMER_ORDER_PRODUCTION_PLANNED':
      return 'lum.business-case.state.customer-order-production-planned.description';

    case 'CUSTOMER_ORDER_IN_PRODUCTION':
      return 'lum.business-case.state.customer-order-in-production.description';

    case 'CUSTOMER_ORDER_PREPARING_FOR_SHIPMENT':
      return 'lum.business-case.state.customer-order-preparing-for-shipment.description';

    case 'CUSTOMER_ORDER_IN_DELIVERY':
      return 'lum.business-case.state.customer-order-in-delivery.description';

    case 'CUSTOMER_ORDER_DELIVERED':
      return 'lum.business-case.state.customer-order-delivered.description';

    case 'CUSTOMER_ORDER_FINISHED':
      return 'lum.business-case.state.customer-order-finished.description';

    case 'CUSTOMER_ORDER_CANCELLATION_RECEIVED':
      return 'lum.business-case.state.customer-order-cancellation-received.description';

    case 'CUSTOMER_ORDER_CANCELLATION_ACCEPTED':
      return 'lum.business-case.state.customer-order-cancellation-accepted.description';

    case 'CUSTOMER_ORDER_CANCELLATION_REJECTED':
      return 'lum.business-case.state.customer-order-cancellation-rejected.description';

    default:
      throw neverError(businessCaseState);
  }
}

export function getBusinessCaseStateLevel(
  businessCaseState: LumBusinessCaseState
): LumBusinessCaseStateLevel {
  switch (businessCaseState) {
    case 'FILE_UPLOAD_FAILURE':
    case 'SUPPLIER_CONNECTOR_DISABLED':
    case 'ROUTING_FAILURE':
    case 'CUSTOMER_CONVERSION_FAILURE':
    case 'SUPPLIER_RECEIVING_FAILURE':
    case 'FILE_DOWNLOAD_FAILURE':
    case 'CUSTOMER_ORDER_IMPORT_FAILED':
    case 'BUSINESS_RELATION_PENDING':
    case 'BUSINESS_RELATION_SUSPENDED':
      return 'error';

    case 'AVAILABLE_FOR_SUPPLIER':
    case 'CREATED':
    case 'ASSIGNED_TO_SUPPLIER':
    case 'ASSIGNED_TO_PLATFORM':
    case 'ROUTED':
    case 'CUSTOMER_ORDER_RECEIVED':
    case 'FORWARDED_TO_SUPPLIER':
    case 'CUSTOMER_REQUEST_RECEIVED':
    case 'CUSTOMER_QUOTATION_SENT':
    case 'CUSTOMER_QUOTATION_ACCEPTED':
    case 'CUSTOMER_ORDER_ACCEPTED':
    case 'CUSTOMER_ORDER_PRODUCTION_PLANNED':
    case 'CUSTOMER_ORDER_IN_PRODUCTION':
    case 'CUSTOMER_ORDER_PREPARING_FOR_SHIPMENT':
    case 'CUSTOMER_ORDER_IN_DELIVERY':
    case 'CUSTOMER_ORDER_DELIVERED':
    case 'CUSTOMER_ORDER_FINISHED':
    case 'CUSTOMER_ORDER_CANCELLATION_RECEIVED':
    case 'CUSTOMER_ORDER_CANCELLATION_ACCEPTED':
      return 'success';

    case 'ASSIGNMENT_FAILURE_SUPPLIER':
    case 'ASSIGNMENT_FAILURE_CUSTOMER':
    case 'CUSTOMER_REQUEST_MISSING_INFORMATION':
    case 'CUSTOMER_REQUEST_REJECTED':
    case 'CUSTOMER_QUOTATION_REJECTED':
    case 'CUSTOMER_ORDER_MISSING_INFORMATION':
    case 'CUSTOMER_ORDER_REJECTED':
    case 'CUSTOMER_ORDER_CANCELLATION_REJECTED':
      return 'warning';

    default:
      throw neverError(businessCaseState);
  }
}

export function getBusinessCaseStateIcon(
  businessCaseState: LumBusinessCaseState
): LumIcon | undefined {
  const level = getBusinessCaseStateLevel(businessCaseState);
  switch (level) {
    case 'success':
      return 'check-circle-16';

    case 'info':
      return 'info-16';

    case 'warning':
      return 'warning-16';

    case 'error':
      return 'error-16';

    case 'none':
      return;

    default:
      throw neverError(level);
  }
}
