import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  LumFormFieldExtComponent,
  LumFormValue,
  LumSelectOption,
} from '@lum-form';
import { getUniqueId } from '@lum-helpers';
import { NgClass, NgFor } from '@angular/common';
import { RadioComponent } from '../radio/radio.component';

@Component({
    selector: 'lum-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        NgFor,
        RadioComponent,
    ]
})
export class RadioGroupComponent<OK extends LumFormValue>
  extends LumFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) options!: LumSelectOption<OK>[];
  @Input() isDisabled: boolean = false;
  @Input() isFlex: boolean = false;

  public groupId = getUniqueId();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public trackByOption(index: number, option: LumSelectOption<OK>): string {
    return option.key.toString();
  }
}
