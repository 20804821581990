import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private readonly apiService: ApiService) {}

  public fetchCurrentBeVersion(): Promise<string | undefined> {
    return firstValueFrom(
      this.apiService.get<string>({
        endpoint: 'version',
        responseType: 'text',
      })
    );
  }
}
