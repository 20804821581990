import { Injectable } from '@angular/core';
import { LumCompany, LumDataView, LumUser } from '@lum-types';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService, LumRequestParams } from '../api.service';
import { LumBaseDataParams } from '../base-data/base-data.abstract';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { CompanyService } from './company.abstract';

@Injectable({
  providedIn: 'root',
})
export class CompanyAPIService
  extends BaseDataAPIService<LumCompany>
  implements CompanyService
{
  private usersQueryParams: LumRequestParams = {};

  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'company';
  }

  public getCurrentCompany(): Observable<LumCompany> {
    return this.apiService.get<LumCompany>({
      endpoint: this._baseApiRoute,
      path: 'current',
    });
  }

  public getCompanies(excludeCurrent?: boolean): Observable<LumCompany[]> {
    return this.apiService.get<LumCompany[]>({
      endpoint: this._baseApiRoute,
      queryParams: {
        excludeCurrent: excludeCurrent ? 'true' : 'false',
      },
    });
  }

  public getCompanyUsers(
    id: number,
    params?: LumBaseDataParams<LumUser>
  ): Observable<LumDataView<LumUser>> {
    this.applyParams<LumUser>(this.usersQueryParams, params);

    return this.apiService.get<LumDataView<LumUser>>({
      endpoint: this._baseApiRoute,
      path: '{id}/user',
      id,
      queryParams: {
        ...this.usersQueryParams,
      },
    });
  }
}
