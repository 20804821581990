import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormFieldComponent, LumFormFieldCheckbox } from '@lum-form';
import { LumDialogInput } from '@lum-types';
import { LumLogger, StorageUtils } from '@lum-utils';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '../../ui/button/button.component';
import { DialogComponent } from '../../ui/dialog/dialog.component';
import { IconComponent } from '../../ui/icon/icon.component';

@Component({
  selector: 'lum-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DialogComponent,
    NgIf,
    IconComponent,
    NgClass,
    ButtonComponent,
    TranslatePipe,
    FormFieldComponent,
  ],
})
export class DialogConfirmComponent implements OnInit {
  public neverShowAgainControl = new FormControl(false);
  public formField: LumFormFieldCheckbox = {
    type: 'checkbox',
    innerLabel: this.translateService.instant('lum.action.never-show-again'),
    dataCy: 'never-show-again',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LumDialogInput,
    private dialogRef: MatDialogRef<LumDialogInput>,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  public onCloseClick(confirm?: boolean): void {
    if (confirm && this.neverShowAgainControl.value) {
      if (this.data.id) {
        StorageUtils.set<boolean>('lum.hide-' + this.data.id, true);
      } else {
        LumLogger.error(
          'DialogConfirmComponent',
          'No id provided for neverShowAgain.'
        );
      }
    }
    this.dialogRef.close(confirm);
  }
}
