import { Injectable } from '@angular/core';
import {
  LumBuildupType,
  LumClientBuildup,
  LumClientBuildupTree,
} from '@lum-types';
import { LumLogger } from '@lum-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { BuildupService } from './buildup.abstract';

@Injectable({
  providedIn: 'root',
})
export class BuildupAPIService
  extends BaseDataAPIService<LumClientBuildup>
  implements BuildupService
{
  public selectedBuildupType$: BehaviorSubject<LumBuildupType | null> =
    new BehaviorSubject<LumBuildupType | null>(null);

  constructor(
    protected override apiService: ApiService,
    protected override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'client-buildup';
  }

  public createClientBuildup(buildup: LumClientBuildup): Observable<void> {
    return this.apiService.post<void>({
      endpoint: this._baseApiRoute,
      body: buildup,
      toastMessage: {
        success: {
          title: 'lum.buildup-assignment.success.title',
          message: 'lum.buildup-assignment.success.message',
        },
        error: {
          title: 'lum.buildup-assignment.error.title',
        },
      },
    });
  }

  public importBuildups(
    file: File | null,
    buildupType: LumBuildupType
  ): Observable<void> {
    if (!file || !buildupType) {
      LumLogger.error('Missing file or type: importBuildups()');
      throw new Error('Missing file or type');
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.apiService.post<void>({
      endpoint: this._baseApiRoute,
      path: '{id}/file-upload',
      id: buildupType,
      body: formData,
      responseType: 'text',
      toastMessage: {
        success: {
          title: 'lum.buildups.import.success.title',
          message: 'lum.buildups.import.success.message',
        },
        error: {
          title: 'lum.buildups.import.error.title',
        },
      },
    });
  }

  public clientBuildupTree(
    clientBuildupId: string,
    buildupType: LumBuildupType
  ): Observable<LumClientBuildupTree[]> {
    return this.apiService.get<LumClientBuildupTree[]>({
      endpoint: this._baseApiRoute,
      path: 'tree',
      queryParams: {
        clientBuildupId,
        buildupType,
      },
    });
  }
}
