import { Injectable } from '@angular/core';
import { LumUser } from '@lum-types';
import { Observable } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { UserService } from './user.abstract';

@Injectable({
  providedIn: 'root',
})
export class UserMockService
  extends BaseDataMockService<LumUser & { id?: number }>
  implements UserService
{
  private users: LumUser[] = [
    {
      username: '123456789',
      email: 'mock@lumeso.com',
      role: 'COMPANY_OWNER',
      companyId: 1,
    },
  ];

  constructor() {
    super();
    this._mockData = this.users;
  }
  public getCurrentUser(): Observable<LumUser> {
    return this.getEntity();
  }
}
