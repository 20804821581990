/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { LumCompany, LumDataView, LumUser } from '@lum-types';
import { Observable, of } from 'rxjs';
import { LumBaseDataParams } from '../base-data/base-data.abstract';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { CompanyService } from './company.abstract';

@Injectable({
  providedIn: 'root',
})
export class CompanyMockService
  extends BaseDataMockService<LumCompany>
  implements CompanyService
{
  private readonly companies: LumCompany[] = [
    {
      id: 1,
      name: 'Company 1',
      branch: 'Branch 1',
      site: 'Site 1',
    },
    {
      id: 2,
      name: 'Company 2',
    },
  ];

  constructor() {
    super();
    this._mockData = this.companies;
  }

  public getCurrentCompany(): Observable<LumCompany> {
    return this.delayResponse(of(this.companies[0]));
  }

  public getCompanies(excludeCurrent?: boolean): Observable<LumCompany[]> {
    return this.delayResponse(of(this.companies));
  }

  public getCompanyUsers(
    id: number,
    params?: LumBaseDataParams<LumUser>
  ): Observable<LumDataView<LumUser>> {
    return of({
      content: [
        { email: 'test@test.com', role: 'COMPANY_OWNER' },
        { email: 'test1@test.com', role: 'PLATFORM_ADMIN' },
      ],
    });
  }
}
