import { Injectable } from '@angular/core';
import { Projection } from '@dcupl/common';
import { DcuplList } from '@dcupl/core';
import {
  LumApiEndpoint,
  LumDataView,
  LumDcuplScript,
  LumFilter,
  LumPagination,
  LumSort,
} from '@lum-types';
import { LumLogger } from '@lum-utils';
import {} from 'cypress/types/lodash';
import { Observable, filter, first, map, mergeMap, of } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService, LumRequestParams } from '../api.service';
import { LumBaseDataParams } from './base-data.abstract';

@Injectable({
  providedIn: 'root',
})
export class BaseDataAPIService<T> {
  set _baseApiRoute(endpoint: LumApiEndpoint) {
    this.baseApiRoute = endpoint;
    this.baseAdminApiRoute = 'admin/' + endpoint;
  }

  get _baseApiRoute(): LumApiEndpoint {
    return this.baseApiRoute;
  }

  public dcuplList?: DcuplList<T>;
  private customDcuplScript?: LumDcuplScript;

  private baseAdminApiRoute!: LumApiEndpoint | string;
  private baseApiRoute!: LumApiEndpoint;

  protected queryParams: LumRequestParams = {};

  constructor(
    protected readonly apiService: ApiService,
    protected readonly dcuplService: DcuplService
  ) {}

  public getAll(
    params?: LumBaseDataParams<T>,
    dcuplModelKey?: string,
    dcuplProjection?: Projection<T>,
    skipPathAll?: boolean
  ): Observable<LumDataView<T>> {
    this.validateBaseApiRoute();
    this.applyParams(this.queryParams, params);

    if (dcuplModelKey) {
      if (params?.sort?.dcuplScript) {
        this.customDcuplScript = params.sort.dcuplScript;
      }
      // get data from dcupl
      return this.getAllFromDcupl(dcuplModelKey, params?.resetDcuplData);
    }

    return this.apiService.authService.user$().pipe(
      filter((user) => !!user),
      first(),
      mergeMap((user) => {
        let endpoint: string = this.baseApiRoute;

        if (user?.role === 'PLATFORM_ADMIN') {
          endpoint = this.baseAdminApiRoute;
        }

        return this.apiService
          .get<unknown>({
            endpoint,
            path: skipPathAll ? undefined : 'all',
            queryParams: this.queryParams,
          })
          .pipe(
            map((data) => {
              if ((data as LumDataView<T>).content) {
                return data;
              }
              return { content: data };
            })
          ) as Observable<LumDataView<T>>;
      })
    );
  }

  private getAllFromDcupl(
    modelKey: string,
    resetDcuplData?: boolean
  ): Observable<LumDataView<T>> {
    try {
      // sort
      const sort = this.dcuplService.getSortParam(this.queryParams);
      if (this.customDcuplScript) {
        sort.script = this.customDcuplScript;
      }

      // paging
      const pagination = this.dcuplService.getPaginationParam(this.queryParams);

      if (resetDcuplData) {
        // reset dcupl data
        this.dcuplList?.destroy();
        this.dcuplList = this.dcuplService.dcupl.lists.create({
          modelKey,
        });
        this.dcuplList.catalog.query.applyOptions({});
      } else {
        // apply filter query params
        this.dcuplService.applyFilterParam(this.queryParams, this.dcuplList);
      }

      this.dcuplList?.catalog.query.applyOptions({
        sort,
        ...pagination,
      });

      const dcuplData = this.dcuplList?.catalog.query.execute();

      // total pages
      const currentSize =
        this.dcuplList?.catalog.fn.metadata().currentSize ?? 0;
      const totalPages = Math.ceil(
        currentSize / Number(this.queryParams['pageSize'])
      );

      return of({
        content: dcuplData as T[],
        number: Number(this.queryParams['pageNumber']),
        totalPages,
        totalElements: currentSize,
      });
    } catch (error) {
      LumLogger.error('Error while getting data from dcupl', error);
      return of({ content: [] });
    }
  }

  public getEntity(): Observable<T> {
    this.validateBaseApiRoute();

    return this.apiService.get<T>({ endpoint: this.baseApiRoute });
  }

  public getEntityById(id: string): Observable<T> {
    this.validateBaseApiRoute();

    return this.apiService.get<T>({
      endpoint: this.baseApiRoute,
      path: '{id}',
      id,
    });
  }

  public deleteEntityById(id: number): Observable<T> {
    this.validateBaseApiRoute();

    return this.apiService.delete<T>({
      endpoint: this.baseApiRoute,
      path: '{id}',
      id,
    });
  }

  protected addFilterQueryParams<Type>(
    queryParams: LumRequestParams,
    filter?: LumFilter<Type>[]
  ): void {
    filter?.forEach((f) => {
      let stringValue: string;
      if (Array.isArray(f.value)) {
        stringValue = f.value.join(',');
      } else {
        stringValue = String(f.value);
      }

      queryParams[`filter[${String(f.propertyName)}]`] = stringValue;
    });
  }

  protected addPaginationQueryParams(
    queryParams: LumRequestParams,
    pagination?: LumPagination
  ): void {
    if (!pagination) {
      // add default pagination
      pagination = { pageNumber: 0, pageSize: 999 };
    }
    queryParams['pageNumber'] = pagination.pageNumber.toString();
    queryParams['pageSize'] = pagination.pageSize.toString();
  }

  protected addSortQueryParams<Type>(
    queryParams: LumRequestParams,
    sort?: LumSort<Type>
  ): void {
    if (sort) {
      const direction = sort.direction === 'asc' ? '+' : '-';
      queryParams['sort'] = direction + String(sort.propertyName);
    }
  }

  protected applyParams<Type>(
    queryParams: LumRequestParams,
    params?: LumBaseDataParams<Type>
  ): void {
    if (params?.filters) {
      this.addFilterQueryParams<Type>(queryParams, params?.filters);
    }
    if (params?.sort) {
      this.addSortQueryParams<Type>(queryParams, params?.sort);
    }
    if (params?.pagination) {
      this.addPaginationQueryParams(queryParams, params?.pagination);
    }
  }

  private validateBaseApiRoute(): void {
    if (!this.baseApiRoute) {
      throw new Error(
        'Base API route not set. Please set it in the constructor.'
      );
    }
  }

  public resetQueryParams(): void {
    this.queryParams = {};
  }
}
