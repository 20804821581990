import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { neverError } from '@lum-helpers';
import { LumIcon, LumIconSize, LumNotificationType } from '@lum-types';
import { NgClass, NgIf } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'lum-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        NgIf,
        IconComponent,
    ]
})
export class NotificationComponent implements OnInit {
  @Input({ required: true }) type!: LumNotificationType;
  @Input() icon?: LumIcon;
  @Input() hasIcon = true;
  @Input() iconSize: LumIconSize = 24;

  constructor() {}

  ngOnInit(): void {
    if (!this.icon) {
      this.icon = this.getDefaultIcon();
    }
  }

  private getDefaultIcon(): LumIcon {
    if (this.type === 'info') {
      if (this.iconSize === 16) {
        return 'info-16';
      }
      return 'info-24';
    }

    if (this.type === 'error') {
      if (this.iconSize === 16) {
        return 'error-16';
      }
      return 'error-24';
    }

    throw neverError(this.type);
  }

  public getClasses(): string[] {
    const classes: string[] = [];

    if (this.type === 'info') {
      classes.push('bg-info-100', 'text-info-600');
    }

    if (this.type === 'error') {
      classes.push('bg-error-100', 'text-error-600');
    }

    return classes;
  }
}
