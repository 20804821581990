<div
  class="flex items-center justify-center form-select"
  [ngClass]="{
    disabled: isDisabled,
    focus: isExpanded,
    'form-error': shouldShowErrors(),
  }"
  [attr.data-cy]="'select-' + dataCy"
>
  @if (control) {
    <mat-select
      [id]="'select-' + dataCy"
      [formControl]="control"
      disableRipple
      (openedChange)="isExpanded = $event"
    >
      @if (placeholder) {
        <mat-option>{{ placeholder }}</mat-option>
      }
      @for (option of options; track option.key) {
        <mat-option [value]="option.key">{{ option.label }}</mat-option>
      }
    </mat-select>
  }

  <div class="lum-select-icon-wrapper">
    <lum-icon
      name="caret-down-16"
      [size]="16"
      class="transition-all pointer-events-none"
      [class.text-gray-400]="isDisabled"
      [class.rotate-180]="isExpanded"
    ></lum-icon>
  </div>
</div>
