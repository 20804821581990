import { Injectable } from '@angular/core';
import { LumToastMessageConfig } from '@lum-types';
import { ToastMessageComponent } from '@lum-ui/toast-message/toast-message.component';
import {
  ActiveToast,
  IndividualConfig,
  ToastContainerDirective,
  ToastrService,
} from 'ngx-toastr';
import { neverError } from '../helpers/never.helper';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  constructor(private readonly toastr: ToastrService) {}

  public triggerToast(
    toastMessageConfig: LumToastMessageConfig
  ): number | undefined {
    const { options, inlineContainer, type, message, title } =
      toastMessageConfig;
    const config = this.applyInlineOptions(options, inlineContainer);

    let toast: ActiveToast<ToastMessageComponent> | undefined;

    switch (type) {
      case 'error':
        toast = this.toastr.error(
          message || 'lum.toast.error.message',
          title || 'lum.toast.error.title',
          config
        );
        break;

      case 'info':
        toast = this.toastr.info(
          message || 'lum.toast.info.message',
          title || 'lum.toast.info.title',
          config
        );
        break;

      case 'success':
        toast = this.toastr.success(
          message || 'lum.toast.success.message',
          title || 'lum.toast.success.title',
          config
        );
        break;

      case 'warning':
        toast = this.toastr.warning(
          message || 'lum.toast.warning.message',
          title || 'lum.toast.warning.title',
          config
        );
        break;

      case 'progress': {
        const customConfig: Partial<IndividualConfig> = {
          ...options,
          disableTimeOut: true,
          progressBar: true,
          tapToDismiss: false,
          closeButton: false,
        };
        toast = this.toastr.info(
          message || 'lum.toast.info.message',
          title || 'lum.toast.info.title',
          customConfig
        );
        break;
      }

      default: {
        throw neverError(type);
      }
    }

    return toast?.toastId;
  }

  public clearAll(): void {
    this.toastr.clear();
  }

  public clearMessage(id: number): boolean {
    return this.toastr.remove(id);
  }

  private setContainer(toastContainer?: ToastContainerDirective): void {
    this.toastr.overlayContainer = toastContainer;
  }

  private applyInlineOptions(
    options?: Partial<IndividualConfig>,
    inlineContainer?: ToastContainerDirective
  ): Partial<IndividualConfig> {
    const config: Partial<IndividualConfig> = inlineContainer
      ? {
          positionClass: 'toast-inline',
          disableTimeOut: true,
          closeButton: false,
        }
      : {};

    Object.assign(config, options);
    this.setContainer(inlineContainer);
    return config;
  }
}
