<div class="relative toast w-80" [ngClass]="this.type">
  <div class="flex items-start gap-2">
    @if (iconName && !isProgressToast) {
      <div
        class="flex items-center justify-center w-8 h-8 text-white rounded-md icon"
      >
        <lum-icon [name]="iconName"></lum-icon>
      </div>
    } @else if (isProgressToast) {
      <div class="text-info-600">
        <lum-spinner [size]="24"></lum-spinner>
      </div>
    }

    <div class="flex flex-col">
      @if (title) {
        <span class="mt-1 font-semibold typo-label-md">
          {{ title | translate }}
        </span>
      }

      @if (message) {
        <p class="mt-1 typo-label-sm" [innerHTML]="message | translate"></p>
      }
    </div>
  </div>

  <div class="absolute left-2 right-2 bottom-2">
    @if (options.progressBar) {
      @if (!isProgressToast) {
        <div class="w-full h-[3px] bg-gray-300 rounded-full overflow-hidden">
          <div class="toast-progress" [style.width]="width() + '%'"></div>
        </div>
      }
    }
  </div>

  @if (options.closeButton) {
    <button
      (click)="onRemoveClick()"
      type="button"
      class="absolute top-3 right-3"
    >
      <lum-icon name="x-16" [size]="16"></lum-icon>
      <span class="sr-only">Close</span>
    </button>
  }
</div>
