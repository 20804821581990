import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { LUM_ENV } from '@lum-environment';

export const LUM_ENVIRONMENT_GUARD_DEV:
  | CanActivateChildFn
  | CanActivateFn = async (route, state) => {
  const canActivate = ['local', 'dev'].includes(LUM_ENV.env);

  if (!canActivate) {
    const router = inject(Router);
    router.navigate(['/']);
  }

  return canActivate;
};

export const LUM_ENVIRONMENT_GUARD_STAGING:
  | CanActivateChildFn
  | CanActivateFn = async (route, state) => {
  const canActivate = ['local', 'dev', 'testing', 'staging'].includes(
    LUM_ENV.env
  );

  if (!canActivate) {
    const router = inject(Router);
    router.navigate(['/']);
  }

  return canActivate;
};
