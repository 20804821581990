/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { LumDataView, LumFilter, LumPagination, LumSort } from '@lum-types';
import { DataUtils } from '@lum-utils';
import { clone, first } from 'lodash-es';
import { Observable, delay, map, of, throwError } from 'rxjs';
import { LumBaseDataParams } from './base-data.abstract';

@Injectable({
  providedIn: 'root',
})
export class BaseDataMockService<T extends { id?: number }> {
  public _mockData: T[] = [];

  private queryParams: LumBaseDataParams<T> = {};

  public getAll(params?: LumBaseDataParams<T>): Observable<LumDataView<T>> {
    this.queryParams = { ...this.queryParams, ...params };

    return this.delayResponse(this.getEntities()).pipe(
      map((items) => {
        let totalPages = 0;
        if (items) {
          items = this.applyFilters(items, this.queryParams?.filters);

          const pageSize = this.queryParams?.pagination?.pageSize;
          totalPages = pageSize
            ? Math.ceil(items.length / pageSize)
            : items.length;

          items = this.applySorting(items, this.queryParams?.sort);
          items = this.applyPagination(items, this.queryParams?.pagination);
        }

        return {
          content: items ?? [],
          totalPages,
          number: this.queryParams.pagination?.pageNumber ?? 0,
        };
      })
    );
  }

  public getEntity(): Observable<T> {
    return this.delayResponse(of(first(this._mockData) ?? ({} as T)));
  }

  protected getEntities(): Observable<T[] | undefined> {
    return of(DataUtils.clone(this._mockData));
  }

  public getEntityById(id: string): Observable<T> {
    const entity = this._mockData.find((e) => e.id === Number(id));
    if (entity) {
      return this.delayResponse(of(clone(entity)!));
    }
    return this.throwElementNotFoundError(id);
  }

  public deleteEntityById(id: number): Observable<T> {
    const entity = this._mockData.find((e) => e.id === id);
    return this.delayResponse(of(clone(entity)!));
  }

  protected delayResponse<T>(
    observable: Observable<T>,
    customDelay?: number
  ): Observable<T> {
    return observable.pipe(delay(customDelay ?? Math.random() * 200));
  }

  protected applyFilter(items: T[], f: LumFilter<T>): T[] {
    // Implement custom filters in subclass
    return items;
  }

  private applyFilters(items: T[], filters?: LumFilter<T>[]): T[] {
    if (filters?.length) {
      filters.forEach((filter) => {
        items = this.applyFilter(items, filter);
      });
    }
    return items;
  }

  private applySorting(items: T[], sort?: LumSort<T>): T[] {
    if (sort) {
      items = this.sortBy(
        items,
        sort.propertyName as string,
        sort.direction == 'asc' ? +1 : -1
      );
    }
    return items;
  }

  private applyPagination(items: T[], pagination?: LumPagination): T[] {
    if (pagination) {
      items.splice(0, pagination.pageNumber * pagination.pageSize);
      items.splice(pagination.pageSize);
    }
    return items;
  }

  private sortBy(items: T[], propertyName: string, direction: number): T[] {
    return items.slice(0).sort((a: any, b: any) => {
      return (
        (a[propertyName] > b[propertyName]
          ? 1
          : a[propertyName] < b[propertyName]
            ? -1
            : 0) * direction
      );
    });
  }

  private throwElementNotFoundError(id: string): Observable<never> {
    return throwError(() => new Error(`No element with id ${id} was found!`));
  }

  public resetQueryParams(): void {
    this.queryParams = {};
  }
}
