export class FileUtils {
  public static downloadFile(url: string, filename: string): void {
    if (url) {
      const download = document.createElement('a');
      download.style.display = 'none';
      download.href = url;
      download.download = filename;
      download.click();
    }
  }
}
