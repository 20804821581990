import { DialogConfirmComponent } from '@lum-dialogs/dialog-confirm/dialog-confirm.component';
import { LumDialog } from '@lum-types';

export type LumTest = { id: string; name: string };

export const LUM_BEFORE_IMPORT_DIALOG: LumDialog = {
  component: DialogConfirmComponent,
  config: {
    data: {
      id: 'dialog-before-import',
      headline: 'lum.import.title',
      content: 'lum.import.description',
      neverShowAgain: true,
    },
  },
};
