import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../../../ui/icon/icon.component';

@Component({
    selector: 'lum-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, IconComponent]
})
export class SelectOptionComponent<T> {
  @Input() option?: T;

  @Input() isSelected: boolean = false;

  @Input() isCheckboxVisible: boolean = false;

  @Input()
  @HostBinding('class.disabled')
  disabled?: boolean = false;

  @Output()
  selected = new EventEmitter<T>();

  @HostListener('click')
  private onClickListener(): void {
    if (!this.disabled) {
      this.selected.emit(this.option);
    }
  }

  constructor() {}
}
