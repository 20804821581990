import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LumFormFieldExtComponent } from '@lum-form';
import { Subscription } from 'rxjs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lum-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ReactiveFormsModule,
        NgClass,
        FormsModule,
    ]
})
export class TextareaComponent<K extends string>
  extends LumFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isDisabled = false;
  @Input() rows = 3;
  @Input() value = '';
  @Input() placeholder = '';

  @ViewChild('textarea') textareaRef?: ElementRef<HTMLInputElement>;

  private subscriptions: Subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.control?.valueChanges.subscribe((value) => {
        this.value = value;
      })
    );

    this.value = this.control?.value;

    this.subscriptions.add(
      this.control?.statusChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    return classes;
  }

  public onBlur(): void {
    this.control?.markAsTouched();
  }

  public onChange(value: string): void {
    this.control?.patchValue(value);
    this.control?.markAsTouched();
    this.control?.markAsDirty();
  }
}
