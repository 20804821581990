import { InjectionToken } from '@angular/core';
import { LUM_LOG_LEVEL } from '../utils/logger.utils';

type LumEnv = 'local' | 'dev' | 'testing' | 'staging' | 'production';

export const LUM_ENVIRONMENT = new InjectionToken('environment');

export type LumEnvironmentInterface = {
  production: boolean;
  env: LumEnv;
  buildTimestamp: number;
  version: string;
  logLevel: LUM_LOG_LEVEL;
  api: {
    baseUrl: string;
    docsUrl?: string;
  };
  dcupl?: {
    baseUrl: string;
    apiUrl?: string;
    applicationKey?: 'connect-lumeso';
  };
  hiddenFeatures?: LumFeature[];

  cognito: {
    userPoolId: string;
    userPoolClientId: string;
    loginWith: {
      oauth: {
        domain: string;
        scopes: string[];
        redirectSignIn: string[];
        redirectSignOut: string[];
        responseType: 'code';
      };
    };
  };
};

export type LumFeature = '';
