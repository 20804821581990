// Sizes

import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig } from '@angular/material/dialog';
import { LumIcon } from './_icon.type';
import { LumIconSize } from './icon.type';

export enum LUM_DIALOG_SIZE {
  M = '64rem',
  L = '72rem',
}

export enum LUM_DIALOG_MAX_SIZE {
  M = '94vw',
}

export type LumDialogInput = {
  id?: string; // needed when we want to skip the dialog next time
  headline: string;
  headlineHighlight?: {
    iconName: LumIcon;
    color?: string;
    iconSize?: LumIconSize;
  };
  content: string;
  cta?: string;
  neverShowAgain?: boolean;
  translateOptions?: Record<string, string>;
};

export type LumDialog = {
  component: ComponentType<unknown>;
  config?: MatDialogConfig<unknown>;
};
