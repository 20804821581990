import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { LumCompany, LumDataSource, LumDataView, LumUser } from '@lum-types';
import { StorageUtils } from '@lum-utils';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataService, LumBaseDataParams } from '../base-data/base-data.abstract';
import { CompanyAPIService } from './company.api.service';
import { CompanyMockService } from './company.mock.service';

export abstract class CompanyService extends BaseDataService<LumCompany> {
  public abstract getCurrentCompany(): Observable<LumCompany>;
  public abstract getCompanies(excludeCurrent?: boolean): Observable<LumCompany[]>;
  public abstract getCompanyUsers(id: number, params?: LumBaseDataParams<LumUser>): Observable<LumDataView<LumUser>>;
}

export function CompanyFactory(apiService: ApiService, dcuplService: DcuplService): CompanyService {
  if (StorageUtils.get<LumDataSource>('lum.data-source') === 'mock-data') {
    return new CompanyMockService();
  }

  return new CompanyAPIService(apiService, dcuplService);
}

export const LUM_COMPANY_PROVIDER: FactoryProvider = {
  provide: CompanyService,
  useFactory: CompanyFactory,
  deps: [ApiService, DcuplService, HttpClient],
};
