/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  LumBuildupType,
  LumClientBuildup,
  LumClientBuildupTree,
} from '@lum-types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseDataMockService } from '../base-data/base-data.mock.service';
import { BuildupService } from './buildup.abstract';

@Injectable({
  providedIn: 'root',
})
export class BuildupMockService
  extends BaseDataMockService<LumClientBuildup>
  implements BuildupService
{
  public selectedBuildupType$: BehaviorSubject<LumBuildupType | null> =
    new BehaviorSubject<LumBuildupType | null>(null);

  private readonly buildups: LumClientBuildup[] = [
    {
      id: 1,
      clientBuildupId: '1-buildup',
      clientBuildupDetails: '1 buildup details',
      connectorId: 1,
      buildupType: 'COMPONENT',
      buildupDefinition: {},
    },
    {
      id: 2,
      clientBuildupId: '2-buildup',
      clientBuildupDetails: '2 buildup details',
      connectorId: 2,
      buildupType: 'COMPONENT',
    },
    {
      id: 3,
      clientBuildupId: '3buildup',
      connectorId: 3,
      buildupType: 'ISO',
    },
  ];

  private readonly buildupTree: LumClientBuildupTree[] = [
    {
      clientBuildupId: this.buildups[0].clientBuildupId,
      clientBuildupDetails: this.buildups[0].clientBuildupDetails,
      buildupType: this.buildups[0].buildupType,
      connectorId: 1,
      products: [
        {
          platformProductId: 'ihzv-fwlck-hlb',
          productCategory: 'GLASS',
          clientProductId: '1',
          clientProductDetails: 'glass details',
        },
        {
          platformProductId: 'cstp',
          productCategory: 'INTERLAYER',
          clientProductId: '2',
          clientProductDetails: 'interlayer details',
        },
        {
          platformProductId: 'gas-15',
          productCategory: 'GAS',
        },
      ],
    },
    {
      clientBuildupId: this.buildups[0].clientBuildupId,
      clientBuildupDetails: this.buildups[0].clientBuildupDetails,
      buildupType: this.buildups[0].buildupType,
      connectorId: 1,
      products: [
        {
          platformProductId: 'ihzv-fwlck-hlb',
          productCategory: 'GLASS',
          clientProductId: '1',
          clientProductDetails: 'glass details',
        },
        {
          platformProductId: 'cstp',
          productCategory: 'INTERLAYER',
          clientProductId: '2',
          clientProductDetails: 'interlayer details',
        },
        {
          platformProductId: 'gas-15',
          productCategory: 'GAS',
        },
      ],
    },
  ];

  constructor() {
    super();
    this._mockData = this.buildups;
  }

  public createClientBuildup(buildup: LumClientBuildup): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public importBuildups(
    file: File | null,
    buildupType: LumBuildupType
  ): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public clientBuildupTree(
    clientBuildupId: string,
    buildupType: LumBuildupType
  ): Observable<LumClientBuildupTree[]> {
    return this.delayResponse(of(this.buildupTree));
  }
}
