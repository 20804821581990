/* 🤖 this file was generated by svg-to-ts */
export const iconAddressBook16: {
  name: 'address-book-16';
  data: string;
} = {
  name: 'address-book-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M4.125 2.625v10.75h8.75V2.625zm-1.25 9.5V13.5c0 .621.504 1.125 1.125 1.125h9c.621 0 1.125-.504 1.125-1.125v-11c0-.621-.504-1.125-1.125-1.125H4c-.621 0-1.125.504-1.125 1.125v1.375H2a.625.625 0 1 0 0 1.25h.875v2.25H2a.625.625 0 1 0 0 1.25h.875v2.25H2a.625.625 0 1 0 0 1.25zm5.625-6.5a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75m1.901 3.185a2.625 2.625 0 1 0-3.803 0A4.4 4.4 0 0 0 5 10.125a.625.625 0 0 0 1.001.75 3.125 3.125 0 0 1 5 0 .625.625 0 0 0 1-.75 4.4 4.4 0 0 0-1.599-1.315Z"/></svg>`,
};
export const iconAlternative16: {
  name: 'alternative-16';
  data: string;
} = {
  name: 'alternative-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M11.391 1.591a.625.625 0 0 1 .884 0l2 2a.625.625 0 0 1 0 .884l-2 2a.625.625 0 0 1-.884-.884l.934-.933H9.091L5.717 8.033l3.375 3.375h3.232l-.933-.933a.625.625 0 1 1 .884-.884l2 2a.625.625 0 0 1 0 .884l-2 2a.625.625 0 0 1-.884-.884l.934-.933H8.832a.63.63 0 0 1-.442-.183L4.574 8.658h-2.74a.625.625 0 0 1 0-1.25h2.74l3.817-3.817a.63.63 0 0 1 .442-.183h3.492l-.934-.933a.625.625 0 0 1 0-.884"/></svg>`,
};
export const iconArrowDown16: {
  name: 'arrow-down-16';
  data: string;
} = {
  name: 'arrow-down-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 1.875c.345 0 .625.28.625.625v9.491l3.433-3.433a.625.625 0 1 1 .884.884l-4.5 4.5a.625.625 0 0 1-.884 0l-4.5-4.5a.625.625 0 1 1 .884-.884l3.433 3.433V2.5c0-.345.28-.625.625-.625"/></svg>`,
};
export const iconArrowLeft16: {
  name: 'arrow-left-16';
  data: string;
} = {
  name: 'arrow-left-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7.442 3.058a.625.625 0 0 1 0 .884L4.009 7.375H13.5a.625.625 0 1 1 0 1.25H4.009l3.433 3.433a.625.625 0 1 1-.884.884l-4.5-4.5a.625.625 0 0 1 0-.884l4.5-4.5a.625.625 0 0 1 .884 0"/></svg>`,
};
export const iconArrowRight16: {
  name: 'arrow-right-16';
  data: string;
} = {
  name: 'arrow-right-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8.558 3.058a.625.625 0 0 1 .884 0l4.5 4.5a.625.625 0 0 1 0 .884l-4.5 4.5a.625.625 0 1 1-.884-.884l3.433-3.433H2.5a.625.625 0 1 1 0-1.25h9.491L8.558 3.942a.625.625 0 0 1 0-.884"/></svg>`,
};
export const iconArrowUp16: {
  name: 'arrow-up-16';
  data: string;
} = {
  name: 'arrow-up-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7.558 2.058a.625.625 0 0 1 .884 0l4.5 4.5a.625.625 0 1 1-.884.884L8.625 4.009V13.5a.625.625 0 1 1-1.25 0V4.009L3.942 7.442a.625.625 0 1 1-.884-.884z"/></svg>`,
};
export const iconBriefcase16: {
  name: 'briefcase-16';
  data: string;
} = {
  name: 'briefcase-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M5.351 1.851A1.63 1.63 0 0 1 6.5 1.375h3A1.625 1.625 0 0 1 11.125 3v.375H13.5c.621 0 1.125.504 1.125 1.125v8c0 .621-.504 1.125-1.125 1.125h-11A1.125 1.125 0 0 1 1.375 12.5v-8c0-.621.504-1.125 1.125-1.125h2.375V3c0-.431.171-.844.476-1.149M2.625 4.625v2.403c1.65.886 3.497 1.35 5.374 1.347h.002a11.3 11.3 0 0 0 5.374-1.348V4.625zm7.25-1.25h-3.75V3a.375.375 0 0 1 .375-.375h3A.375.375 0 0 1 9.875 3zm3.5 5.052A12.6 12.6 0 0 1 8 9.625a12.6 12.6 0 0 1-5.375-1.198v3.948h10.75zM6.375 7c0-.345.28-.625.625-.625h2a.625.625 0 1 1 0 1.25H7A.625.625 0 0 1 6.375 7"/></svg>`,
};
export const iconCalendar16: {
  name: 'calendar-16';
  data: string;
} = {
  name: 'calendar-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M5 .875c.345 0 .625.28.625.625v.375h4.75V1.5a.625.625 0 1 1 1.25 0v.375H13c.621 0 1.125.504 1.125 1.125v10c0 .621-.504 1.125-1.125 1.125H3A1.125 1.125 0 0 1 1.875 13V3c0-.621.504-1.125 1.125-1.125h1.375V1.5c0-.345.28-.625.625-.625m-.625 2.25h-1.25v1.75h9.75v-1.75h-1.25V3.5a.625.625 0 1 1-1.25 0v-.375h-4.75V3.5a.625.625 0 1 1-1.25 0zm8.5 3h-9.75v6.75h9.75zm-6.046.843a.63.63 0 0 1 .296.532v4a.625.625 0 1 1-1.25 0V8.511l-.095.048a.625.625 0 1 1-.56-1.118l1-.5a.63.63 0 0 1 .609.027m2.736 1.165a.375.375 0 0 0-.39.18.625.625 0 1 1-1.082-.627 1.625 1.625 0 1 1 2.706 1.79l-1.049 1.4h.75a.625.625 0 1 1 0 1.25h-2a.625.625 0 0 1-.5-1l1.799-2.398"/></svg>`,
};
export const iconCaretDown16: {
  name: 'caret-down-16';
  data: string;
} = {
  name: 'caret-down-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M2.558 5.558a.625.625 0 0 1 .884 0L8 10.116l4.558-4.558a.625.625 0 1 1 .884.884l-5 5a.625.625 0 0 1-.884 0l-5-5a.625.625 0 0 1 0-.884"/></svg>`,
};
export const iconCaretUp16: {
  name: 'caret-up-16';
  data: string;
} = {
  name: 'caret-up-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7.558 1.558a.625.625 0 0 1 .884 0l3 3a.625.625 0 1 1-.884.884L8 2.884 5.442 5.442a.625.625 0 1 1-.884-.884zm-3 9a.625.625 0 0 1 .884 0L8 13.116l2.558-2.558a.625.625 0 1 1 .884.884l-3 3a.625.625 0 0 1-.884 0l-3-3a.625.625 0 0 1 0-.884"/></svg>`,
};
export const iconCheck16: {
  name: 'check-16';
  data: string;
} = {
  name: 'check-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M14.442 4.058a.625.625 0 0 1 0 .884l-8 8a.625.625 0 0 1-.884 0l-3.5-3.5a.625.625 0 1 1 .884-.884L6 11.616l7.558-7.558a.625.625 0 0 1 .884 0"/></svg>`,
};
export const iconCheckCircle16: {
  name: 'check-circle-16';
  data: string;
} = {
  name: 'check-circle-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 2.625a5.375 5.375 0 1 0 0 10.75 5.375 5.375 0 0 0 0-10.75M1.375 8a6.625 6.625 0 1 1 13.25 0 6.625 6.625 0 0 1-13.25 0m9.567-1.942a.625.625 0 0 1 0 .884l-3.5 3.5a.625.625 0 0 1-.884 0l-1.5-1.5a.625.625 0 1 1 .884-.884L7 9.116l3.058-3.058a.625.625 0 0 1 .884 0"/></svg>`,
};
export const iconCopy16: {
  name: 'copy-16';
  data: string;
} = {
  name: 'copy-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M5.5 1.875a.625.625 0 0 0-.625.625v2.375H2.5a.625.625 0 0 0-.625.625v8c0 .345.28.625.625.625h8c.345 0 .625-.28.625-.625v-2.375H13.5c.345 0 .625-.28.625-.625v-8a.625.625 0 0 0-.625-.625zm5.625 8h1.75v-6.75h-6.75v1.75H10.5c.345 0 .625.28.625.625zM5.5 6.125H3.125v6.75h6.75v-6.75z"/></svg>`,
};
export const iconDashboard16: {
  name: 'dashboard-16';
  data: string;
} = {
  name: 'dashboard-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M3.625 3.625v2.75h2.75v-2.75zM2.375 3.5c0-.621.504-1.125 1.125-1.125h3c.621 0 1.125.504 1.125 1.125v3c0 .621-.504 1.125-1.125 1.125h-3A1.125 1.125 0 0 1 2.375 6.5zm7.25.125v2.75h2.75v-2.75zM8.375 3.5c0-.621.504-1.125 1.125-1.125h3c.621 0 1.125.504 1.125 1.125v3c0 .621-.504 1.125-1.125 1.125h-3A1.125 1.125 0 0 1 8.375 6.5zm-4.75 6.125v2.75h2.75v-2.75zM2.375 9.5c0-.621.504-1.125 1.125-1.125h3c.621 0 1.125.504 1.125 1.125v3c0 .621-.504 1.125-1.125 1.125h-3A1.125 1.125 0 0 1 2.375 12.5zm7.25.125v2.75h2.75v-2.75zM8.375 9.5c0-.621.504-1.125 1.125-1.125h3c.621 0 1.125.504 1.125 1.125v3c0 .621-.504 1.125-1.125 1.125h-3A1.125 1.125 0 0 1 8.375 12.5z"/></svg>`,
};
export const iconDecline16: {
  name: 'decline-16';
  data: string;
} = {
  name: 'decline-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path fill-rule="evenodd" d="M4.667 3.783a5.375 5.375 0 0 1 7.55 7.55zm-.884.884a5.375 5.375 0 0 0 7.55 7.55zM8 1.375a6.625 6.625 0 1 0 0 13.25 6.625 6.625 0 0 0 0-13.25"/></svg>`,
};
export const iconDelete16: {
  name: 'delete-16';
  data: string;
} = {
  name: 'delete-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M6.5.875A1.625 1.625 0 0 0 4.875 2.5v.375H2.5a.625.625 0 1 0 0 1.25h.375V13A1.125 1.125 0 0 0 4 14.125h8A1.125 1.125 0 0 0 13.125 13V4.125h.375a.625.625 0 1 0 0-1.25h-2.375V2.5A1.625 1.625 0 0 0 9.5.875zm3.375 2V2.5a.375.375 0 0 0-.375-.375h-3a.375.375 0 0 0-.375.375v.375zM5.5 4.125H4.125v8.75h7.75v-8.75zm1 1.75c.345 0 .625.28.625.625v4a.625.625 0 1 1-1.25 0v-4c0-.345.28-.625.625-.625m3.625.625a.625.625 0 1 0-1.25 0v4a.625.625 0 1 0 1.25 0z"/></svg>`,
};
export const iconDownload16: {
  name: 'download-16';
  data: string;
} = {
  name: 'download-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path fill-rule="evenodd" d="M8 1.375c.345 0 .625.28.625.625v5.491l1.433-1.433a.625.625 0 1 1 .884.884l-2.5 2.5a.623.623 0 0 1-.884 0l-2.5-2.5a.625.625 0 1 1 .884-.884l1.433 1.433V2c0-.345.28-.625.625-.625m-5.5 7c.345 0 .625.28.625.625v3.375h9.75V9a.625.625 0 1 1 1.25 0v4c0 .345-.28.625-.625.625h-11A.625.625 0 0 1 1.875 13V9c0-.345.28-.625.625-.625"/></svg>`,
};
export const iconEdit16: {
  name: 'edit-16';
  data: string;
} = {
  name: 'edit-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="m10.707 2.677 2.616 2.614L12 6.615 9.384 4zM8.5 4.884l-5.375 5.375v2.616h2.616l5.374-5.376zm1.412-3.18a1.125 1.125 0 0 1 1.59 0l2.793 2.791a1.126 1.126 0 0 1 0 1.591L7.51 12.875h5.99a.625.625 0 0 1 0 1.25H3A1.125 1.125 0 0 1 1.875 13v-2.793c0-.298.118-.584.33-.795z"/></svg>`,
};
export const iconError16: {
  name: 'error-16';
  data: string;
} = {
  name: 'error-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 2.625a5.375 5.375 0 1 0 0 10.75 5.375 5.375 0 0 0 0-10.75M1.375 8a6.625 6.625 0 1 1 13.25 0 6.625 6.625 0 0 1-13.25 0m4.183-2.442a.625.625 0 0 1 .884 0L8 7.116l1.558-1.558a.625.625 0 1 1 .884.884L8.884 8l1.558 1.558a.625.625 0 1 1-.884.884L8 8.884l-1.558 1.558a.625.625 0 1 1-.884-.884L7.116 8 5.558 6.442a.625.625 0 0 1 0-.884"/></svg>`,
};
export const iconExternalLink16: {
  name: 'external-link-16';
  data: string;
} = {
  name: 'external-link-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z"/><path d="M12.667 12.667H3.333V3.333H8V2H3.333C2.593 2 2 2.6 2 3.333v9.334C2 13.4 2.593 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V8h-1.333zM9.333 2v1.333h2.394L5.173 9.887l.94.94 6.554-6.554v2.394H14V2z" fill="currentColor" fill-rule="nonzero"/></g></svg>`,
};
export const iconGenerate16: {
  name: 'generate-16';
  data: string;
} = {
  name: 'generate-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7.88 1.997A6.13 6.13 0 0 0 3.562 3.74a.625.625 0 1 0 .874.895 4.875 4.875 0 0 1 6.854.04l.7.7H10.5a.625.625 0 1 0 0 1.25h3c.345 0 .625-.28.625-.625V3a.625.625 0 1 0-1.25 0v1.491l-.7-.7a6.13 6.13 0 0 0-4.296-1.794ZM1.874 10c0-.345.28-.625.625-.625h3a.625.625 0 1 1 0 1.25h-1.49l.7.7a4.874 4.874 0 0 0 6.854.04.625.625 0 0 1 .874.895 6.125 6.125 0 0 1-8.611-.05l-.701-.701V13a.625.625 0 1 1-1.25 0z"/></svg>`,
};
export const iconHandshake16: {
  name: 'handshake-16';
  data: string;
} = {
  name: 'handshake-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7.828 2.899a.63.63 0 0 1 .344 0l3.266.933 1.335-.667a1.127 1.127 0 0 1 1.51.503l1.552 3.105a1.127 1.127 0 0 1-.503 1.51l-1.462.73.072-.071L13.5 8.5l.559-.28-.28-.559.883-.44.055.111a.125.125 0 0 1 .056-.167l-.111.055-1.442-2.88.112-.056a.125.125 0 0 1-.167-.056m0 0 .056.112-.883.44-.279-.559-.559.28.28.559.559-.28 1.44 2.882-.559.28.28.559-.442-.442-.319.319-1.625-3.252h.386m-2.247 0h1.86zm0 0L6.704 7.598c.83.456 1.922.398 2.853-.538a.625.625 0 0 1 .833-.048l2.064 1.65.285-.285.202.403.559-.28.28.559.09-.045-3.428 3.428a.63.63 0 0 1-.594.164l-4-1a.6.6 0 0 1-.211-.097l-3.46-2.472.044.022L2.5 8.5l.559.28.239-.478 2.969 2.12-.115-.028L6 11l.363-.509-.096-.069 3.541.886-.25.25L10 12l.152-.606-.344-.086 1.756-1.756-1.543-1.235C8.698 9.35 7.108 9.345 5.896 8.57a1.125 1.125 0 0 1-.192-1.745l.007-.007 2.647-2.567L8 4.15l-3.064.875-1.638 3.277-.435-.31L2.5 8.5l.28-.559-.56-.28L3.663 4.78l.559.279L4.5 4.5l.28-.559-.218-.109L7.828 2.9m-4.6.266a1.127 1.127 0 0 0-1.51.503L.164 6.773a1.127 1.127 0 0 0 .503 1.51m0 0 1.509.754-.04-.028L2.5 8.5l-.559-.28.28-.559-.882-.44 1.44-2.882.883.44.279-.559.559.28m-1.665-.273-.055.112-.112-.056a.125.125 0 0 0 .167-.056M1.34 7.22l-.112-.055a.125.125 0 0 1 .056.167zm3.223-3.388-1.335-.667zM2.98 11.653a.625.625 0 0 1 .867-.173l1.41.94 1.895.474a.625.625 0 1 1-.304 1.212l-2-.5a.6.6 0 0 1-.195-.086l-1.5-1a.625.625 0 0 1-.173-.867"/></svg>`,
};
export const iconHardDrive16: {
  name: 'hard-drive-16';
  data: string;
} = {
  name: 'hard-drive-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M3.125 3.625v2.75h9.75v-2.75zM1.875 3.5c0-.621.504-1.125 1.125-1.125h10c.621 0 1.125.504 1.125 1.125v3c0 .621-.504 1.125-1.125 1.125H3A1.125 1.125 0 0 1 1.875 6.5zM10.642 5c0-.345.28-.625.625-.625h.01a.625.625 0 1 1 0 1.25h-.01A.625.625 0 0 1 10.642 5M3.125 9.625v2.75h9.75v-2.75zM1.875 9.5c0-.621.504-1.125 1.125-1.125h10c.621 0 1.125.504 1.125 1.125v3c0 .621-.504 1.125-1.125 1.125H3A1.125 1.125 0 0 1 1.875 12.5zm8.767 1.5c0-.345.28-.625.625-.625h.01a.625.625 0 1 1 0 1.25h-.01a.625.625 0 0 1-.625-.625"/></svg>`,
};
export const iconInfo16: {
  name: 'info-16';
  data: string;
} = {
  name: 'info-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 2.625a5.375 5.375 0 1 0 0 10.75 5.375 5.375 0 0 0 0-10.75M1.375 8a6.625 6.625 0 1 1 13.25 0 6.625 6.625 0 0 1-13.25 0M8 4.708c.345 0 .625.28.625.625v.011a.625.625 0 1 1-1.25 0v-.01c0-.346.28-.626.625-.626M6.875 7.5c0-.345.28-.625.625-.625A1.125 1.125 0 0 1 8.625 8v2.387a.625.625 0 0 1-.125 1.238A1.125 1.125 0 0 1 7.375 10.5V8.113a.625.625 0 0 1-.5-.613"/></svg>`,
};
export const iconMail16: {
  name: 'mail-16';
  data: string;
} = {
  name: 'mail-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M1.375 3.5c0-.345.28-.625.625-.625h12c.345 0 .625.28.625.625V12a1.125 1.125 0 0 1-1.125 1.125h-11A1.125 1.125 0 0 1 1.375 12zm2.232.625 3.711 3.402.026.023.656.602.656-.601.026-.024 3.711-3.402zm9.768.796L10.015 8l3.36 3.079V4.92Zm-.982 6.954H3.607l3.302-3.027.669.613a.625.625 0 0 0 .844 0l.669-.613zM5.984 8 2.625 4.92v6.16L5.985 8Z"/></svg>`,
};
export const iconMenu0116: {
  name: 'menu-01-16';
  data: string;
} = {
  name: 'menu-01-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M1.875 6c0-.345.28-.625.625-.625h11a.625.625 0 1 1 0 1.25h-11A.625.625 0 0 1 1.875 6m0 4c0-.345.28-.625.625-.625h11a.625.625 0 1 1 0 1.25h-11A.625.625 0 0 1 1.875 10"/></svg>`,
};
export const iconMenu0216: {
  name: 'menu-02-16';
  data: string;
} = {
  name: 'menu-02-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M1.875 4c0-.345.28-.625.625-.625h11a.625.625 0 1 1 0 1.25h-11A.625.625 0 0 1 1.875 4m0 4c0-.345.28-.625.625-.625h11a.625.625 0 1 1 0 1.25h-11A.625.625 0 0 1 1.875 8m0 4c0-.345.28-.625.625-.625h11a.625.625 0 1 1 0 1.25h-11A.625.625 0 0 1 1.875 12"/></svg>`,
};
export const iconMenu0316: {
  name: 'menu-03-16';
  data: string;
} = {
  name: 'menu-03-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7.25 4A.75.75 0 0 1 8 3.25h.011a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 4m0 4A.75.75 0 0 1 8 7.25h.011a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 8m0 4a.75.75 0 0 1 .75-.75h.011a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75"/></svg>`,
};
export const iconMinus16: {
  name: 'minus-16';
  data: string;
} = {
  name: 'minus-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M1.875 8c0-.345.28-.625.625-.625h11a.625.625 0 1 1 0 1.25h-11A.625.625 0 0 1 1.875 8"/></svg>`,
};
export const iconNews16: {
  name: 'news-16';
  data: string;
} = {
  name: 'news-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M3.625 4.125V11.5q0 .191-.044.375H13a.375.375 0 0 0 .375-.375V4.125zm-1.625 9A1.625 1.625 0 0 1 .375 11.5v-6a.625.625 0 1 1 1.25 0v6a.375.375 0 0 0 .75 0V4A1.125 1.125 0 0 1 3.5 2.875h10A1.125 1.125 0 0 1 14.625 4v7.5A1.625 1.625 0 0 1 13 13.125zM5.375 7c0-.345.28-.625.625-.625h5a.625.625 0 1 1 0 1.25H6A.625.625 0 0 1 5.375 7m0 2c0-.345.28-.625.625-.625h5a.625.625 0 1 1 0 1.25H6A.625.625 0 0 1 5.375 9"/></svg>`,
};
export const iconNotification16: {
  name: 'notification-16';
  data: string;
} = {
  name: 'notification-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 2.625A3.875 3.875 0 0 0 4.125 6.5c0 2.148-.46 3.96-.914 4.875h9.579c-.455-.916-.915-2.727-.915-4.875A3.875 3.875 0 0 0 8 2.625m2.55 10h2.452a1.125 1.125 0 0 0 .97-1.688c-.34-.586-.847-2.267-.847-4.437a5.125 5.125 0 1 0-10.25 0c0 2.17-.507 3.85-.846 4.437a1.126 1.126 0 0 0 .967 1.688H5.45a2.626 2.626 0 0 0 5.1 0m-1.325 0h-2.45a1.375 1.375 0 0 0 2.45 0"/></svg>`,
};
export const iconPending16: {
  name: 'pending-16';
  data: string;
} = {
  name: 'pending-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path fill-rule="evenodd" d="M8.123 1.949a.625.625 0 0 1-.572.674C4.795 2.85 2.625 5.18 2.625 8A5.375 5.375 0 0 0 8 13.375c2.82 0 5.15-2.17 5.377-4.926a.625.625 0 1 1 1.246.102c-.281 3.405-3.152 6.074-6.623 6.074A6.625 6.625 0 0 1 1.375 8c0-3.47 2.67-6.342 6.074-6.623a.625.625 0 0 1 .674.572M8 3.875c.345 0 .625.28.625.625v2.875H11.5a.625.625 0 1 1 0 1.25H8A.625.625 0 0 1 7.375 8V4.5c0-.345.28-.625.625-.625m2.75-1.625a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m1.5 2.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5M14.5 6A.75.75 0 1 1 13 6a.75.75 0 0 1 1.5 0"/></svg>`,
};
export const iconPhone16: {
  name: 'phone-16';
  data: string;
} = {
  name: 'phone-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M5.104 2.639a2.396 2.396 0 0 0-1.979 2.36A7.876 7.876 0 0 0 11 12.876a2.4 2.4 0 0 0 2.361-1.979l-2.785-1.248-1.461 1.242a1.12 1.12 0 0 1-1.162.125h-.004c-1.186-.573-2.383-1.754-2.962-2.94l-.002-.004a1.12 1.12 0 0 1 .12-1.16l1.247-1.484L5.104 2.64Zm-.058-1.256a1.125 1.125 0 0 1 1.165.67l1.323 2.955a1.13 1.13 0 0 1-.138 1.119l-1.245 1.48c.457.88 1.375 1.787 2.26 2.241l1.458-1.24a1.13 1.13 0 0 1 1.126-.141l.01.003 2.943 1.32a1.125 1.125 0 0 1 .669 1.164l-.001.009a3.646 3.646 0 0 1-3.617 3.162A9.125 9.125 0 0 1 1.875 5a3.646 3.646 0 0 1 3.162-3.616z"/></svg>`,
};
export const iconPlus16: {
  name: 'plus-16';
  data: string;
} = {
  name: 'plus-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 1.875c.345 0 .625.28.625.625v4.875H13.5a.625.625 0 1 1 0 1.25H8.625V13.5a.625.625 0 1 1-1.25 0V8.625H2.5a.625.625 0 1 1 0-1.25h4.875V2.5c0-.345.28-.625.625-.625"/></svg>`,
};
export const iconQuestion16: {
  name: 'question-16';
  data: string;
} = {
  name: 'question-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 2.625a5.375 5.375 0 1 0 0 10.75 5.375 5.375 0 0 0 0-10.75M1.375 8a6.625 6.625 0 1 1 13.25 0 6.625 6.625 0 0 1-13.25 0m4-1.25c0-1.387 1.256-2.375 2.625-2.375s2.625.988 2.625 2.375c0 1.173-.898 2.06-2.003 2.306A.625.625 0 0 1 7.375 9v-.5c0-.345.28-.625.625-.625.84 0 1.375-.58 1.375-1.125S8.84 5.625 8 5.625s-1.375.58-1.375 1.125V7a.625.625 0 1 1-1.25 0zM8 10.708c.345 0 .625.28.625.625v.011a.625.625 0 1 1-1.25 0v-.01c0-.346.28-.626.625-.626"/></svg>`,
};
export const iconSearch16: {
  name: 'search-16';
  data: string;
} = {
  name: 'search-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M7 2.625a4.375 4.375 0 1 0 0 8.75 4.375 4.375 0 0 0 0-8.75M1.375 7a5.625 5.625 0 1 1 10.02 3.511l3.047 3.047a.625.625 0 0 1-.884.884l-3.047-3.047A5.625 5.625 0 0 1 1.375 7"/></svg>`,
};
export const iconSettings16: {
  name: 'settings-16';
  data: string;
} = {
  name: 'settings-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M6.228 1.356a.63.63 0 0 1 .55.116l1.13.904h.186l1.131-.904a.63.63 0 0 1 .55-.115 6.8 6.8 0 0 1 1.672.69c.171.1.286.275.308.472l.161 1.439q.067.064.131.13l1.438.162a.63.63 0 0 1 .471.307c.304.524.537 1.086.693 1.672a.63.63 0 0 1-.116.55l-.904 1.132v.185l.904 1.13a.63.63 0 0 1 .116.55 6.8 6.8 0 0 1-.688 1.672.63.63 0 0 1-.472.309l-1.439.161-.13.131-.162 1.438a.63.63 0 0 1-.307.471 6.8 6.8 0 0 1-1.672.693.63.63 0 0 1-.55-.116l-1.132-.904h-.184l-1.132.904a.63.63 0 0 1-.55.116 6.8 6.8 0 0 1-1.67-.688.63.63 0 0 1-.31-.472l-.161-1.439-.132-.132-1.441-.169a.63.63 0 0 1-.468-.307 6.8 6.8 0 0 1-.693-1.67.63.63 0 0 1 .116-.552l.904-1.13v-.186l-.904-1.131a.63.63 0 0 1-.115-.55 6.8 6.8 0 0 1 .69-1.672.63.63 0 0 1 .472-.308l1.439-.161.13-.131.162-1.438a.63.63 0 0 1 .309-.472 6.8 6.8 0 0 1 1.67-.687Zm-.772 1.623-.151 1.344a.63.63 0 0 1-.206.398 4 4 0 0 0-.373.373.63.63 0 0 1-.398.206l-1.346.15a5.6 5.6 0 0 0-.33.8l.845 1.057a.62.62 0 0 1 .136.427q-.015.264 0 .529a.63.63 0 0 1-.136.427l-.845 1.058q.135.413.331.8l1.35.158a.63.63 0 0 1 .394.205q.175.198.373.373a.63.63 0 0 1 .206.398l.151 1.345q.386.196.798.328l1.059-.845a.63.63 0 0 1 .427-.136q.264.015.528 0a.63.63 0 0 1 .427.136l1.058.845q.412-.135.798-.33l.151-1.346a.63.63 0 0 1 .207-.398q.197-.175.373-.373a.63.63 0 0 1 .397-.206l1.345-.151q.196-.386.328-.798l-.845-1.059a.62.62 0 0 1-.136-.427 5 5 0 0 0 0-.528.63.63 0 0 1 .136-.427l.845-1.058a5.6 5.6 0 0 0-.33-.798l-1.346-.151a.63.63 0 0 1-.397-.206 5 5 0 0 0-.374-.373.63.63 0 0 1-.206-.398l-.151-1.346a5.6 5.6 0 0 0-.798-.33l-1.058.845a.63.63 0 0 1-.427.136 5 5 0 0 0-.529 0 .63.63 0 0 1-.427-.136l-1.06-.846a5.6 5.6 0 0 0-.795.328ZM8 6.125a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75M4.875 8a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0"/></svg>`,
};
export const iconSortingAscending16: {
  name: 'sorting-ascending-16';
  data: string;
} = {
  name: 'sorting-ascending-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="m13.354 9.646-5-5a.5.5 0 0 0-.708 0l-5 5A.5.5 0 0 0 3 10.5h10a.5.5 0 0 0 .354-.854"/></svg>`,
};
export const iconSortingDecending16: {
  name: 'sorting-decending-16';
  data: string;
} = {
  name: 'sorting-decending-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M13.462 5.809A.5.5 0 0 0 13 5.5H3a.5.5 0 0 0-.354.854l5 5a.5.5 0 0 0 .708 0l5-5a.5.5 0 0 0 .108-.545"/></svg>`,
};
export const iconSortingInactive16: {
  name: 'sorting-inactive-16';
  data: string;
} = {
  name: 'sorting-inactive-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M11.462 10.809a.5.5 0 0 1-.108.545l-3 3a.5.5 0 0 1-.708 0l-3-3A.5.5 0 0 1 5 10.5h6a.5.5 0 0 1 .462.309M5 5.5h6a.5.5 0 0 0 .354-.854l-3-3a.5.5 0 0 0-.708 0l-3 3A.5.5 0 0 0 5 5.5"/></svg>`,
};
export const iconUser16: {
  name: 'user-16';
  data: string;
} = {
  name: 'user-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8 2.625a3.375 3.375 0 1 0 0 6.75 3.375 3.375 0 0 0 0-6.75m2.59 7.207a4.625 4.625 0 1 0-5.18 0 7.62 7.62 0 0 0-3.951 3.355.625.625 0 0 0 1.082.626C3.648 11.9 5.648 10.625 8 10.625s4.352 1.275 5.459 3.188a.625.625 0 0 0 1.082-.626 7.62 7.62 0 0 0-3.95-3.355Z"/></svg>`,
};
export const iconWarning16: {
  name: 'warning-16';
  data: string;
} = {
  name: 'warning-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M8.359 2.825a.422.422 0 0 0-.718 0l-5.466 9.491c-.13.229.023.559.359.559h10.932c.336 0 .49-.33.359-.558zm1.083-.623c-.634-1.103-2.25-1.103-2.884 0zm-2.884 0L1.09 11.695c-.635 1.109.201 2.43 1.443 2.43h10.932c1.242 0 2.078-1.321 1.443-2.43L9.442 2.202M8 5.875c.345 0 .625.28.625.625V9a.625.625 0 1 1-1.25 0V6.5c0-.345.28-.625.625-.625m-.625 5.375c0-.345.28-.625.625-.625h.005a.625.625 0 1 1 0 1.25H8a.625.625 0 0 1-.625-.625"/></svg>`,
};
export const iconX16: {
  name: 'x-16';
  data: string;
} = {
  name: 'x-16',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill-rule="evenodd" d="M3.058 3.058a.625.625 0 0 1 .884 0L8 7.116l4.058-4.058a.625.625 0 1 1 .884.884L8.884 8l4.058 4.058a.625.625 0 1 1-.884.884L8 8.884l-4.058 4.058a.625.625 0 1 1-.884-.884L7.116 8 3.058 3.942a.625.625 0 0 1 0-.884"/></svg>`,
};
export const iconAddressBook24: {
  name: 'address-book-24';
  data: string;
} = {
  name: 'address-book-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M4.5 3.75A1.5 1.5 0 0 1 6 2.25h13.5a1.5 1.5 0 0 1 1.5 1.5v16.5a1.5 1.5 0 0 1-1.5 1.5H6a1.5 1.5 0 0 1-1.5-1.5V18H3a.75.75 0 0 1 0-1.5h1.5v-3.75H3a.75.75 0 0 1 0-1.5h1.5V7.5H3A.75.75 0 0 1 3 6h1.5zM6 20.25h13.5V3.75H6zm6.75-12a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5m2.526 5.022a3.75 3.75 0 1 0-5.052 0A6.4 6.4 0 0 0 7.65 15.3a.75.75 0 1 0 1.2.9 4.874 4.874 0 0 1 7.8 0 .75.75 0 1 0 1.2-.9 6.4 6.4 0 0 0-2.574-2.028"/></svg>`,
};
export const iconAlternative24: {
  name: 'alternative-24';
  data: string;
} = {
  name: 'alternative-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M17.22 2.52a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72h-5.38l-5.25 5.25 5.25 5.25h5.38l-1.72-1.72a.75.75 0 0 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72h-5.69a.75.75 0 0 1-.53-.22L6.94 12.8H2.75a.75.75 0 1 1 0-1.5h4.19l5.78-5.78a.75.75 0 0 1 .53-.22h5.69l-1.72-1.72a.75.75 0 0 1 0-1.06"/></svg>`,
};
export const iconArrowDown24: {
  name: 'arrow-down-24';
  data: string;
} = {
  name: 'arrow-down-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3a.75.75 0 0 1 .75.75v14.69l5.47-5.47a.75.75 0 1 1 1.06 1.06l-6.75 6.75a.75.75 0 0 1-1.06 0l-6.75-6.75a.75.75 0 1 1 1.06-1.06l5.47 5.47V3.75A.75.75 0 0 1 12 3"/></svg>`,
};
export const iconArrowLeft24: {
  name: 'arrow-left-24';
  data: string;
} = {
  name: 'arrow-left-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0"/></svg>`,
};
export const iconArrowRight24: {
  name: 'arrow-right-24';
  data: string;
} = {
  name: 'arrow-right-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06"/></svg>`,
};
export const iconArrowUp24: {
  name: 'arrow-up-24';
  data: string;
} = {
  name: 'arrow-up-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M11.47 3.22a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 1 1-1.06 1.06l-5.47-5.47v14.69a.75.75 0 0 1-1.5 0V5.56l-5.47 5.47a.75.75 0 1 1-1.06-1.06z"/></svg>`,
};
export const iconBriefcase24: {
  name: 'briefcase-24';
  data: string;
} = {
  name: 'briefcase-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M8.159 2.909A2.25 2.25 0 0 1 9.75 2.25h4.5A2.25 2.25 0 0 1 16.5 4.5v.75h3.75a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5v-12a1.5 1.5 0 0 1 1.5-1.5H7.5V4.5c0-.597.237-1.169.659-1.591M3.75 6.75v3.904a17.2 17.2 0 0 0 8.249 2.096H12a17.16 17.16 0 0 0 8.249-2.097V6.75zM15 5.25H9V4.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75zm5.25 7.092A18.7 18.7 0 0 1 12 14.25a18.7 18.7 0 0 1-8.25-1.908v6.408h16.5zM9.75 10.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75"/></svg>`,
};
export const iconCalendar24: {
  name: 'calendar-24';
  data: string;
} = {
  name: 'calendar-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M7.5 1.5a.75.75 0 0 1 .75.75V3h7.5v-.75a.75.75 0 0 1 1.5 0V3h2.25A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3h2.25v-.75a.75.75 0 0 1 .75-.75m-.75 3H4.5v3h15v-3h-2.25v.75a.75.75 0 0 1-1.5 0V4.5h-7.5v.75a.75.75 0 0 1-1.5 0zM19.5 9h-15v10.5h15zm-9.356 1.612a.75.75 0 0 1 .356.638v6a.75.75 0 0 1-1.5 0v-4.787l-.415.208a.75.75 0 1 1-.67-1.342l1.5-.75a.75.75 0 0 1 .73.033Zm4.236 1.403a.75.75 0 0 0-.78.362.75.75 0 1 1-1.298-.754 2.25 2.25 0 1 1 3.746 2.48L14.25 16.5h1.5a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.6-1.2l2.698-3.597a.75.75 0 0 0-.468-1.188"/></svg>`,
};
export const iconCaretDown24: {
  name: 'caret-down-24';
  data: string;
} = {
  name: 'caret-down-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3.97 8.47a.75.75 0 0 1 1.06 0L12 15.44l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 0-1.06"/></svg>`,
};
export const iconCaretUp24: {
  name: 'caret-up-24';
  data: string;
} = {
  name: 'caret-up-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M11.47 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L12 4.06 8.03 8.03a.75.75 0 0 1-1.06-1.06zm-4.5 13.5a.75.75 0 0 1 1.06 0L12 19.94l3.97-3.97a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 0-1.06"/></svg>`,
};
export const iconCategory24: {
  name: 'category-24';
  data: string;
} = {
  name: 'category-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3.25 4A.75.75 0 0 1 4 3.25h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75H4a.75.75 0 0 1-.75-.75zm1.5.75v4.5h4.5v-4.5zm8.5-.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75zm1.5.75v4.5h4.5v-4.5zM3.25 14a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-.75.75H4a.75.75 0 0 1-.75-.75zm1.5.75v4.5h4.5v-4.5zm9.598-.402a3.75 3.75 0 1 1 5.304 5.304 3.75 3.75 0 0 1-5.304-5.304M17 14.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5"/></svg>`,
};
export const iconCheck24: {
  name: 'check-24';
  data: string;
} = {
  name: 'check-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M21.53 6.22a.75.75 0 0 1 0 1.06l-12 12a.75.75 0 0 1-1.06 0l-5.25-5.25a.75.75 0 1 1 1.06-1.06L9 17.69 20.47 6.22a.75.75 0 0 1 1.06 0"/></svg>`,
};
export const iconCheckCircle24: {
  name: 'check-circle-24';
  data: string;
} = {
  name: 'check-circle-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12m14.03-2.78a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 4.72-4.72a.75.75 0 0 1 1.06 0"/></svg>`,
};
export const iconCopy24: {
  name: 'copy-24';
  data: string;
} = {
  name: 'copy-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M8.25 3a.75.75 0 0 0-.75.75V7.5H3.75a.75.75 0 0 0-.75.75v12c0 .414.336.75.75.75h12a.75.75 0 0 0 .75-.75V16.5h3.75a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-.75-.75zm8.25 12h3V4.5H9v3h6.75a.75.75 0 0 1 .75.75zM8.25 9H4.5v10.5H15V9z"/></svg>`,
};
export const iconDashboard24: {
  name: 'dashboard-24';
  data: string;
} = {
  name: 'dashboard-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3.75 5.25a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5zm6 0h-4.5v4.5h4.5zm3 0a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5zm6 0h-4.5v4.5h4.5zm-15 9a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5zm6 0h-4.5v4.5h4.5zm3 0a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5zm6 0h-4.5v4.5h4.5z"/></svg>`,
};
export const iconDelete24: {
  name: 'delete-24';
  data: string;
} = {
  name: 'delete-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M9.75 1.5A2.25 2.25 0 0 0 7.5 3.75v.75H3.75a.75.75 0 0 0 0 1.5h.75v13.5A1.5 1.5 0 0 0 6 21h12a1.5 1.5 0 0 0 1.5-1.5V6h.75a.75.75 0 0 0 0-1.5H16.5v-.75a2.25 2.25 0 0 0-2.25-2.25zm5.25 3v-.75a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75v.75zM8.25 6H6v13.5h12V6zm1.5 3a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6A.75.75 0 0 1 9.75 9M15 15.75v-6a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0"/></svg>`,
};
export const iconDelivery24: {
  name: 'delivery-24';
  data: string;
} = {
  name: 'delivery-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M1 5.25a.75.75 0 0 0 0 1.5h13.25v9.5h-3.604a2.75 2.75 0 0 0-5.292 0H3.75V11.5a.75.75 0 0 0-1.5 0v4.9a1.35 1.35 0 0 0 1.35 1.35h1.754a2.75 2.75 0 0 0 5.292 0h4.708a2.75 2.75 0 0 0 5.292 0H22.4a1.35 1.35 0 0 0 1.35-1.35v-2.775c0-.188-.04-.374-.117-.546l-1.79-4.028a1.35 1.35 0 0 0-1.233-.801h-4.86V6.6a1.35 1.35 0 0 0-1.35-1.35zm21.25 11h-1.604a2.75 2.75 0 0 0-4.896-.831V9.75h4.762l1.738 3.91zm-15.134-.134a1.25 1.25 0 1 1 1.768 1.768 1.25 1.25 0 0 1-1.768-1.768m10 0a1.25 1.25 0 1 1 1.768 1.768 1.25 1.25 0 0 1-1.768-1.768M2 8.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5z"/></svg>`,
};
export const iconDeliverydate24: {
  name: 'deliverydate-24';
  data: string;
} = {
  name: 'deliverydate-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M7.5 1.588a.66.66 0 0 0-.663.662v.838H4.5A1.41 1.41 0 0 0 3.087 4.5v15A1.413 1.413 0 0 0 4.5 20.913h15a1.413 1.413 0 0 0 1.413-1.413v-15A1.41 1.41 0 0 0 19.5 3.088h-2.337V2.25a.663.663 0 0 0-1.325 0v.838H8.162V2.25a.66.66 0 0 0-.662-.662m-3.062 2.85a.1.1 0 0 1 .062-.025h2.337v.837a.662.662 0 1 0 1.325 0v-.837h7.675v.837a.663.663 0 0 0 1.326 0v-.837H19.5a.09.09 0 0 1 .087.087v3.088H4.412V4.5q.001-.035.026-.062M4.412 19.5V8.913h15.175V19.5a.09.09 0 0 1-.087.087h-15a.09.09 0 0 1-.088-.087m10.963-8.162a.66.66 0 0 0-.468.194l-4.032 4.03-1.782-1.78-.002-.003a.662.662 0 0 0-.937.937l2.253 2.252a.663.663 0 0 0 .936 0l4.5-4.5a.662.662 0 0 0-.468-1.13"/></svg>`,
};
export const iconDetail24: {
  name: 'detail-24';
  data: string;
} = {
  name: 'detail-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M4.409 5.409A2.25 2.25 0 0 1 6 4.75h12A2.25 2.25 0 0 1 20.25 7v10A2.25 2.25 0 0 1 18 19.25H6A2.25 2.25 0 0 1 3.75 17V7c0-.597.237-1.169.659-1.591M6 3.25A3.75 3.75 0 0 0 2.25 7v10A3.75 3.75 0 0 0 6 20.75h12A3.75 3.75 0 0 0 21.75 17V7A3.75 3.75 0 0 0 18 3.25zm0 12a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5zM5.25 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75M6 7.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5z"/></svg>`,
};
export const iconDeveloper24: {
  name: 'developer-24';
  data: string;
} = {
  name: 'developer-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path fill-rule="evenodd" d="M5.25 2.25a1.5 1.5 0 0 0-1.5 1.5v16.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-12a.75.75 0 0 0-.22-.53l-5.25-5.25a.75.75 0 0 0-.53-.22m-.75 1.5H5.25v16.5h13.5V9h-4.5a.75.75 0 0 1-.75-.75zm4.19 3.75L15 4.81V7.5zm-.66 6.22-2.25-2.25a.75.75 0 1 0-1.06 1.06l1.72 1.72-1.72 1.72a.75.75 0 1 0 1.06 1.06l2.25-2.25a.75.75 0 0 0 0-1.06m-6.75-2.25a.75.75 0 0 1 0 1.06l-1.72 1.72 1.72 1.72a.75.75 0 1 1-1.06 1.06l-2.25-2.25a.75.75 0 0 1 0-1.06l2.25-2.25a.75.75 0 0 1 1.06 0"/></svg>`,
};
export const iconEdit24: {
  name: 'edit-24';
  data: string;
} = {
  name: 'edit-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M15 2.689a1.5 1.5 0 0 1 2.121 0l4.19 4.187a1.5 1.5 0 0 1 0 2.12l-2.748 2.75a1 1 0 0 1-.068.067L10.81 19.5h9.44a.75.75 0 0 1 0 1.5H4.5A1.5 1.5 0 0 1 3 19.5v-4.19a1.5 1.5 0 0 1 .439-1.06l8.78-8.78zM12.75 7.06 4.5 15.31v4.19h4.19l8.248-8.251-4.188-4.188Zm5.249 3.127L13.81 6l2.25-2.25 4.19 4.186z"/></svg>`,
};
export const iconError24: {
  name: 'error-24';
  data: string;
} = {
  name: 'error-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12m6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06"/></svg>`,
};
export const iconGenerate24: {
  name: 'generate-24';
  data: string;
} = {
  name: 'generate-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M11.818 3.183a9 9 0 0 0-6.342 2.562.75.75 0 0 0 1.048 1.073 7.5 7.5 0 0 1 10.544.061L18.44 8.25h-2.69a.75.75 0 0 0 0 1.5h4.497a.75.75 0 0 0 .533-.22A.75.75 0 0 0 21 9V4.5a.75.75 0 0 0-1.5 0v2.69l-1.37-1.372a9 9 0 0 0-6.312-2.635M3 15a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5H5.56l1.372 1.37a7.5 7.5 0 0 0 10.544.062.75.75 0 0 1 1.048 1.073 9 9 0 0 1-12.653-.073L4.5 16.81v2.69a.75.75 0 0 1-1.5 0z"/></svg>`,
};
export const iconHandshake24: {
  name: 'handshake-24';
  data: string;
} = {
  name: 'handshake-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M23.84 10.117 21.449 5.33a1.5 1.5 0 0 0-2.013-.67l-2.331 1.165-4.912-1.3a.76.76 0 0 0-.384 0l-4.912 1.3-2.331-1.166a1.5 1.5 0 0 0-2.013.67L.159 10.117a1.5 1.5 0 0 0 .67 2.013l2.532 1.266 5.202 3.715a.8.8 0 0 0 .254.117l6 1.5a.75.75 0 0 0 .713-.196l5.162-5.164 2.477-1.239a1.5 1.5 0 0 0 .67-2.011zm-5.145 3.128-3.226-2.584a.75.75 0 0 0-1.002.058c-1.67 1.681-3.53 1.469-4.717.719L13.804 7.5h2.982l2.55 5.101zM3.893 6l1.92.958-2.398 4.786-1.915-.957zm10.875 11.169-5.447-1.362-4.613-3.294 2.625-5.25L12 6.026l.919.243L8.7 10.364l-.007.008a1.5 1.5 0 0 0 .255 2.326c1.927 1.231 4.253 1.032 6.085-.468l2.592 2.082zm5.813-5.426-2.393-4.781L20.107 6l2.393 4.787-1.92.956Zm-8.227 8.688a.75.75 0 0 1-.909.546L7.538 20a.7.7 0 0 1-.254-.117l-2.47-1.765a.75.75 0 0 1 .872-1.219l2.354 1.682 3.773.942a.75.75 0 0 1 .545.909z"/></svg>`,
};
export const iconHardDrive24: {
  name: 'hard-drive-24';
  data: string;
} = {
  name: 'hard-drive-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3 5.25a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 9.75zm16.5 0h-15v4.5h15zM16.15 7.5a.75.75 0 0 1 .75-.75h.017a.75.75 0 0 1 0 1.5H16.9a.75.75 0 0 1-.75-.75M3 14.25a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5zm16.5 0h-15v4.5h15zm-3.35 2.25a.75.75 0 0 1 .75-.75h.017a.75.75 0 0 1 0 1.5H16.9a.75.75 0 0 1-.75-.75"/></svg>`,
};
export const iconId24: {
  name: 'id-24';
  data: string;
} = {
  name: 'id-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M4.409 5.409A2.25 2.25 0 0 1 6 4.75h12A2.25 2.25 0 0 1 20.25 7v10A2.25 2.25 0 0 1 18 19.25H6A2.25 2.25 0 0 1 3.75 17V7c0-.597.237-1.169.659-1.591M6 3.25A3.75 3.75 0 0 0 2.25 7v10A3.75 3.75 0 0 0 6 20.75h12A3.75 3.75 0 0 0 21.75 17V7A3.75 3.75 0 0 0 18 3.25zm3 4a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5m-.884 1.866a1.25 1.25 0 1 1 1.768 1.768 1.25 1.25 0 0 1-1.768-1.768M15 7.25a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5zm0 4a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5zm-8 4a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5z"/></svg>`,
};
export const iconInfo24: {
  name: 'info-24';
  data: string;
} = {
  name: 'info-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12M12 7.25a.75.75 0 0 1 .75.75v.017a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75m-1.5 4a.75.75 0 0 1 .75-.75 1.5 1.5 0 0 1 1.5 1.5v3.75a.75.75 0 0 1 0 1.5 1.5 1.5 0 0 1-1.5-1.5V12a.75.75 0 0 1-.75-.75"/></svg>`,
};
export const iconLumesoBuildup24: {
  name: 'lumeso-buildup-24';
  data: string;
} = {
  name: 'lumeso-buildup-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#004FFF" fill-rule="evenodd" d="M3.75 2.5A1.5 1.5 0 0 0 2.25 4v14.75a1.5 1.5 0 0 0 1.5 1.5h16.583a1.417 1.417 0 0 0 1.417-1.417V6.25a1.5 1.5 0 0 0-1.5-1.5h-8L9.65 2.8a1.5 1.5 0 0 0-.9-.3zm0 1.5h5l2.8 2.1a.75.75 0 0 0 .45.15h8.25v12.5H3.75zm6 4a.75.75 0 0 0-1.5 0v8c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5H9.75z"/></svg>`,
};
export const iconLumesoBuildupAlert24: {
  name: 'lumeso-buildup-alert-24';
  data: string;
} = {
  name: 'lumeso-buildup-alert-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#004FFF" fill-rule="evenodd" d="M3.75 2.5A1.5 1.5 0 0 0 2.25 4v14.75a1.5 1.5 0 0 0 1.5 1.5h7.292a.75.75 0 0 0 0-1.5H3.75V4h5l2.8 2.1a.75.75 0 0 0 .45.15h8.25v6.292a.75.75 0 0 0 1.5 0V6.25a1.5 1.5 0 0 0-1.5-1.5h-8L9.65 2.8a1.5 1.5 0 0 0-.9-.3zm6 5.5a.75.75 0 0 0-1.5 0v8c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5H9.75z"/><path fill="#FF7A00" fill-rule="evenodd" d="M18.25 14.875a.17.17 0 0 0-.151.082l-4.205 7.301a.13.13 0 0 0 .002.135c.026.045.075.08.149.08h8.41a.17.17 0 0 0 .15-.08.13.13 0 0 0 .001-.134l-4.205-7.302a.17.17 0 0 0-.151-.082m1.235-.541c-.543-.945-1.926-.945-2.47 0Zm-2.47 0-4.205 7.302v.001c-.547.955.175 2.085 1.235 2.085h8.41c1.06 0 1.782-1.13 1.236-2.085l-4.206-7.303m-1.235 2.753c.345 0 .625.28.625.625v1.538a.625.625 0 1 1-1.25 0v-1.538c0-.345.28-.625.625-.625m-.625 4.28c0-.346.28-.626.625-.626h.004a.625.625 0 1 1 0 1.25h-.004a.625.625 0 0 1-.625-.625Z"/></svg>`,
};
export const iconLumesoPoductAlert24: {
  name: 'lumeso-poduct-alert-24';
  data: string;
} = {
  name: 'lumeso-poduct-alert-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#004FFF" fill-rule="evenodd" d="M4.409 4.409A2.25 2.25 0 0 1 6 3.75h12A2.25 2.25 0 0 1 20.25 6v6a.75.75 0 0 0 1.5 0V6A3.75 3.75 0 0 0 18 2.25H6A3.75 3.75 0 0 0 2.25 6v12A3.75 3.75 0 0 0 6 21.75h4a.75.75 0 0 0 0-1.5H6A2.25 2.25 0 0 1 3.75 18V6c0-.597.237-1.169.659-1.591M10.75 8a.75.75 0 0 0-1.5 0v8c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5h-3.25z"/><path fill="#FF7A00" fill-rule="evenodd" d="M18.25 14.875a.17.17 0 0 0-.151.082l-4.205 7.301a.13.13 0 0 0 .002.135c.026.045.075.08.149.08h8.41a.17.17 0 0 0 .15-.08.13.13 0 0 0 .001-.134l-4.205-7.302a.17.17 0 0 0-.151-.082m1.235-.541c-.543-.945-1.926-.945-2.47 0zm-2.47 0-4.205 7.302v.001c-.547.955.175 2.085 1.235 2.085h8.41c1.06 0 1.783-1.13 1.236-2.085l-4.206-7.303m-1.235 2.753c.345 0 .625.28.625.625v1.538a.625.625 0 1 1-1.25 0v-1.538c0-.345.28-.625.625-.625m-.625 4.28c0-.346.28-.626.625-.626h.004a.625.625 0 1 1 0 1.25h-.004a.625.625 0 0 1-.625-.625Z"/></svg>`,
};
export const iconLumesoProcessing24: {
  name: 'lumeso-processing-24';
  data: string;
} = {
  name: 'lumeso-processing-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#004FFF" fill-rule="evenodd" d="m11.279 2.942-6.74 3.974a1.47 1.47 0 0 0-.789 1.3v7.285a1.48 1.48 0 0 0 .809 1.315l6.728 4.257a1.52 1.52 0 0 0 1.426 0l6.728-4.257a1.48 1.48 0 0 0 .809-1.314V8.213a1.48 1.48 0 0 0-.746-1.291 1 1 0 0 1-.138-.1l-6.579-3.88a1.58 1.58 0 0 0-1.508 0m9.153 2.791a.8.8 0 0 0-.143-.109l-6.75-3.98a3.08 3.08 0 0 0-3.012 0l-6.74 3.975A2.97 2.97 0 0 0 2.25 8.217v7.287a2.98 2.98 0 0 0 1.526 2.592l6.731 4.258a3.02 3.02 0 0 0 2.986 0l6.732-4.259a2.97 2.97 0 0 0 1.525-2.593V8.22a2.98 2.98 0 0 0-1.318-2.487M9.348 9.348a3.75 3.75 0 1 1 5.304 5.304 3.75 3.75 0 0 1-5.304-5.304M12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5"/></svg>`,
};
export const iconLumesoProcessingAlert24: {
  name: 'lumeso-processing-alert-24';
  data: string;
} = {
  name: 'lumeso-processing-alert-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#004FFF" fill-rule="evenodd" d="m11.279 2.942-6.74 3.974a1.47 1.47 0 0 0-.789 1.3v7.285a1.48 1.48 0 0 0 .809 1.315l5.906 3.736a.75.75 0 1 1-.802 1.268l-5.887-3.724a2.98 2.98 0 0 1-1.526-2.592V8.217c0-1.081.59-2.073 1.536-2.598l6.741-3.975a3.08 3.08 0 0 1 3.012 0l6.75 3.98q.079.047.143.11A2.98 2.98 0 0 1 21.75 8.22v3.64a.75.75 0 0 1-1.5 0V8.213a1.48 1.48 0 0 0-.746-1.291 1 1 0 0 1-.138-.1l-6.579-3.88a1.58 1.58 0 0 0-1.508 0m-1.93 6.406a3.75 3.75 0 1 1 5.303 5.304 3.75 3.75 0 0 1-5.304-5.304ZM12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5"/><path fill="#FF7A00" fill-rule="evenodd" d="M18.25 14.875a.17.17 0 0 0-.151.082l-4.205 7.301a.13.13 0 0 0 .002.135c.026.045.075.08.149.08h8.41a.17.17 0 0 0 .15-.08.13.13 0 0 0 .001-.134l-4.205-7.302a.17.17 0 0 0-.151-.082m1.235-.541c-.543-.945-1.926-.945-2.47 0Zm-2.47 0-4.205 7.302v.001c-.547.955.175 2.085 1.235 2.085h8.41c1.06 0 1.782-1.13 1.236-2.085l-4.206-7.303m-1.235 2.753c.345 0 .625.28.625.625v1.538a.625.625 0 1 1-1.25 0v-1.538c0-.345.28-.625.625-.625m-.625 4.28c0-.346.28-.626.625-.626h.004a.625.625 0 1 1 0 1.25h-.004a.625.625 0 0 1-.625-.625Z"/></svg>`,
};
export const iconLumesoProduct24: {
  name: 'lumeso-product-24';
  data: string;
} = {
  name: 'lumeso-product-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M4.409 4.409A2.25 2.25 0 0 1 6 3.75h12A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6c0-.597.237-1.169.659-1.591M6 2.25A3.75 3.75 0 0 0 2.25 6v12A3.75 3.75 0 0 0 6 21.75h12A3.75 3.75 0 0 0 21.75 18V6A3.75 3.75 0 0 0 18 2.25zM10.75 8a.75.75 0 0 0-1.5 0v8c0 .414.336.75.75.75h4a.75.75 0 0 0 0-1.5h-3.25z"/></svg>`,
};
export const iconMail24: {
  name: 'mail-24';
  data: string;
} = {
  name: 'mail-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M2.25 5.25A.75.75 0 0 1 3 4.5h18a.75.75 0 0 1 .75.75V18a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5zM4.928 6l5.925 5.432.034.03L12 12.482l1.113-1.02.034-.03L19.072 6zm15.322.955L14.746 12l5.504 5.046zM19.072 18l-5.436-4.982-1.13 1.035a.75.75 0 0 1-1.013 0l-1.13-1.035L4.93 18h14.143ZM3.75 17.046 9.254 12 3.75 6.955z"/></svg>`,
};
export const iconMenu0124: {
  name: 'menu-01-24';
  data: string;
} = {
  name: 'menu-01-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3 9a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 9m0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 15"/></svg>`,
};
export const iconMenu0224: {
  name: 'menu-02-24';
  data: string;
} = {
  name: 'menu-02-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6m0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12m0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 18"/></svg>`,
};
export const iconMenu0324: {
  name: 'menu-03-24';
  data: string;
} = {
  name: 'menu-03-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M10.75 6c0-.69.56-1.25 1.25-1.25h.017a1.25 1.25 0 0 1 0 2.5H12c-.69 0-1.25-.56-1.25-1.25m0 6c0-.69.56-1.25 1.25-1.25h.017a1.25 1.25 0 0 1 0 2.5H12c-.69 0-1.25-.56-1.25-1.25m0 6c0-.69.56-1.25 1.25-1.25h.017a1.25 1.25 0 0 1 0 2.5H12c-.69 0-1.25-.56-1.25-1.25"/></svg>`,
};
export const iconMinus24: {
  name: 'minus-24';
  data: string;
} = {
  name: 'minus-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12"/></svg>`,
};
export const iconMyBuildup24: {
  name: 'my-buildup-24';
  data: string;
} = {
  name: 'my-buildup-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path fill-rule="evenodd" d="M2.69 2.94a1.5 1.5 0 0 1 1.06-.44h5c.324 0 .64.105.9.3l2.6 1.95h8a1.5 1.5 0 0 1 1.5 1.5v12.583a1.417 1.417 0 0 1-1.417 1.417H3.75a1.5 1.5 0 0 1-1.5-1.5V4c0-.398.158-.78.44-1.06M8.75 4h-5v14.75h16.5V6.25H12a.75.75 0 0 1-.45-.15z"/></svg>`,
};
export const iconMyBuildupAlert24: {
  name: 'my-buildup-alert-24';
  data: string;
} = {
  name: 'my-buildup-alert-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#01194E" fill-rule="evenodd" d="M2.69 2.94a1.5 1.5 0 0 1 1.06-.44h5c.324 0 .64.105.9.3l2.6 1.95h8a1.5 1.5 0 0 1 1.5 1.5v6.292a.75.75 0 0 1-1.5 0V6.25H12a.75.75 0 0 1-.45-.15L8.75 4h-5v14.75h7.292a.75.75 0 0 1 0 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5V4c0-.398.158-.78.44-1.06"/><path fill="#FF7A00" fill-rule="evenodd" d="M18.25 14.875a.17.17 0 0 0-.151.082l-4.205 7.301a.13.13 0 0 0 .002.135c.026.045.075.08.149.08h8.41a.17.17 0 0 0 .15-.08.13.13 0 0 0 .001-.134l-4.205-7.302a.17.17 0 0 0-.151-.082m1.235-.541c-.543-.945-1.926-.945-2.47 0Zm-2.47 0-4.205 7.302v.001c-.547.955.175 2.085 1.235 2.085h8.41c1.06 0 1.782-1.13 1.236-2.085l-4.206-7.303m-1.235 2.753c.345 0 .625.28.625.625v1.538a.625.625 0 1 1-1.25 0v-1.538c0-.345.28-.625.625-.625m-.625 4.28c0-.346.28-.626.625-.626h.004a.625.625 0 1 1 0 1.25h-.004a.625.625 0 0 1-.625-.625Z"/></svg>`,
};
export const iconMyProcessing24: {
  name: 'my-processing-24';
  data: string;
} = {
  name: 'my-processing-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path fill-rule="evenodd" d="m11.279 2.942-6.74 3.974a1.47 1.47 0 0 0-.789 1.3v7.285a1.48 1.48 0 0 0 .809 1.315l6.728 4.257a1.52 1.52 0 0 0 1.426 0l6.728-4.257a1.48 1.48 0 0 0 .809-1.314V8.213a1.48 1.48 0 0 0-.746-1.291 1 1 0 0 1-.138-.1l-6.579-3.88a1.58 1.58 0 0 0-1.508 0m9.153 2.791a.8.8 0 0 0-.143-.109l-6.75-3.98a3.08 3.08 0 0 0-3.012 0l-6.74 3.975A2.97 2.97 0 0 0 2.25 8.217v7.287a2.98 2.98 0 0 0 1.526 2.592l6.731 4.258a3.02 3.02 0 0 0 2.986 0l6.732-4.259a2.97 2.97 0 0 0 1.525-2.593V8.22a2.98 2.98 0 0 0-1.318-2.487M9.348 9.348a3.75 3.75 0 1 1 5.304 5.304 3.75 3.75 0 0 1-5.304-5.304M12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5"/></svg>`,
};
export const iconMyProcessingAlert24: {
  name: 'my-processing-alert-24';
  data: string;
} = {
  name: 'my-processing-alert-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#01194E" fill-rule="evenodd" d="m11.279 2.942-6.74 3.974a1.47 1.47 0 0 0-.789 1.3v7.285a1.48 1.48 0 0 0 .809 1.315l5.906 3.736a.75.75 0 1 1-.802 1.268l-5.887-3.724a2.98 2.98 0 0 1-1.526-2.592V8.217c0-1.081.59-2.073 1.536-2.598l6.741-3.975a3.08 3.08 0 0 1 3.012 0l6.75 3.98q.079.047.143.11A2.98 2.98 0 0 1 21.75 8.22v3.64a.75.75 0 0 1-1.5 0V8.213a1.48 1.48 0 0 0-.746-1.291 1 1 0 0 1-.138-.1l-6.579-3.88a1.58 1.58 0 0 0-1.508 0m-1.93 6.406a3.75 3.75 0 1 1 5.303 5.304 3.75 3.75 0 0 1-5.304-5.304ZM12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5"/><path fill="#FF7A00" fill-rule="evenodd" d="M18.25 14.875a.17.17 0 0 0-.151.082l-4.205 7.301a.13.13 0 0 0 .002.135c.026.045.075.08.149.08h8.41a.17.17 0 0 0 .15-.08.13.13 0 0 0 .001-.134l-4.205-7.302a.17.17 0 0 0-.151-.082m1.235-.541c-.543-.945-1.926-.945-2.47 0Zm-2.47 0-4.205 7.302v.001c-.547.955.175 2.085 1.235 2.085h8.41c1.06 0 1.782-1.13 1.236-2.085l-4.206-7.303m-1.235 2.753c.345 0 .625.28.625.625v1.538a.625.625 0 1 1-1.25 0v-1.538c0-.345.28-.625.625-.625m-.625 4.28c0-.346.28-.626.625-.626h.004a.625.625 0 1 1 0 1.25h-.004a.625.625 0 0 1-.625-.625Z"/></svg>`,
};
export const iconMyProduct24: {
  name: 'my-product-24';
  data: string;
} = {
  name: 'my-product-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M6 3.75A2.25 2.25 0 0 0 3.75 6v3.19l5.44-5.44zm0-1.5A3.75 3.75 0 0 0 2.25 6v12A3.75 3.75 0 0 0 6 21.75h12A3.75 3.75 0 0 0 21.75 18V6A3.75 3.75 0 0 0 18 2.25zm5.31 1.5-7.56 7.56v3.88L15.19 3.75zm6 0L3.75 17.31V18A2.25 2.25 0 0 0 6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75z"/></svg>`,
};
export const iconMyProductAlert24: {
  name: 'my-product-alert-24';
  data: string;
} = {
  name: 'my-product-alert-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#01194E" fill-rule="evenodd" d="M6 3.75A2.25 2.25 0 0 0 3.75 6v3.19l5.44-5.44zm0-1.5A3.75 3.75 0 0 0 2.25 6v12A3.75 3.75 0 0 0 6 21.75h4a.75.75 0 0 0 0-1.5H6A2.25 2.25 0 0 1 3.75 18v-.69L17.31 3.75H18A2.25 2.25 0 0 1 20.25 6v6a.75.75 0 0 0 1.5 0V6A3.75 3.75 0 0 0 18 2.25zm5.31 1.5-7.56 7.56v3.88L15.19 3.75z"/><path fill="#FF7A00" fill-rule="evenodd" d="M18.25 14.875a.17.17 0 0 0-.151.082l-4.205 7.301a.13.13 0 0 0 .002.135c.026.045.075.08.149.08h8.41a.17.17 0 0 0 .15-.08.13.13 0 0 0 .001-.134l-4.205-7.302a.17.17 0 0 0-.151-.082m1.235-.541c-.543-.945-1.926-.945-2.47 0Zm-2.47 0-4.205 7.302v.001c-.547.955.175 2.085 1.235 2.085h8.41c1.06 0 1.782-1.13 1.236-2.085l-4.206-7.303m-1.235 2.753c.345 0 .625.28.625.625v1.538a.625.625 0 1 1-1.25 0v-1.538c0-.345.28-.625.625-.625m-.625 4.28c0-.346.28-.626.625-.626h.004a.625.625 0 1 1 0 1.25h-.004a.625.625 0 0 1-.625-.625Z"/></svg>`,
};
export const iconNews24: {
  name: 'news-24';
  data: string;
} = {
  name: 'news-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M4.19 4.94a1.5 1.5 0 0 1 1.06-.44h15a1.5 1.5 0 0 1 1.5 1.5v11.25a2.25 2.25 0 0 1-2.25 2.25H3a2.25 2.25 0 0 1-2.25-2.25v-9a.75.75 0 0 1 1.5 0v9a.75.75 0 1 0 1.5 0V6c0-.398.158-.78.44-1.06M5.12 18H19.5a.75.75 0 0 0 .75-.75V6h-15v11.25c0 .258-.044.511-.129.75Zm3.129-7.5A.75.75 0 0 1 9 9.75h7.5a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Zm0 3a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z"/></svg>`,
};
export const iconNotification24: {
  name: 'notification-24';
  data: string;
} = {
  name: 'notification-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3.75a6 6 0 0 0-6 6c0 3.439-.79 6.28-1.495 7.5h14.992C18.791 16.03 18 13.19 18 9.75a6 6 0 0 0-6-6m3.674 15h3.829a1.5 1.5 0 0 0 1.293-2.25c-.531-.918-1.296-3.474-1.296-6.75a7.5 7.5 0 0 0-15 0c0 3.275-.764 5.832-1.294 6.75a1.502 1.502 0 0 0 1.29 2.25h3.83a3.75 3.75 0 0 0 7.348 0m-1.553 0H9.88a2.251 2.251 0 0 0 4.242 0Z"/></svg>`,
};
export const iconPending24: {
  name: 'pending-24';
  data: string;
} = {
  name: 'pending-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path fill-rule="evenodd" d="M16.125 3.375a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0m-4.127-.437a.75.75 0 0 1-.686.81C7.08 4.096 3.75 7.671 3.75 12A8.25 8.25 0 0 0 12 20.25c4.328 0 7.903-3.33 8.253-7.562a.75.75 0 0 1 1.494.124C21.334 17.82 17.11 21.75 12 21.75A9.75 9.75 0 0 1 2.25 12c0-5.109 3.93-9.334 8.938-9.747a.75.75 0 0 1 .81.685M12 6a.75.75 0 0 1 .75.75v4.5h4.5a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75V6.75A.75.75 0 0 1 12 6m6.375.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25M21.75 9a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0"/></svg>`,
};
export const iconPhone24: {
  name: 'phone-24';
  data: string;
} = {
  name: 'phone-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M7.592 2.26a1.5 1.5 0 0 1 1.553.894l1.984 4.431a1.5 1.5 0 0 1-.124 1.412 1 1 0 0 1-.054.072l-1.947 2.316c.7 1.426 2.206 2.914 3.64 3.61l2.282-1.94.07-.054a1.5 1.5 0 0 1 1.423-.13l.01.005 4.417 1.98a1.5 1.5 0 0 1 .893 1.553l-.001.01A5.28 5.28 0 0 1 16.499 21 13.5 13.5 0 0 1 3 7.5m4.768-3.75A3.78 3.78 0 0 0 4.5 7.5a12 12 0 0 0 12 12 3.78 3.78 0 0 0 3.75-3.268l-.012-.006-4.406-1.974-2.281 1.94a1.49 1.49 0 0 1-1.542.162l-.004-.002c-1.741-.84-3.506-2.582-4.356-4.322l-.002-.005a1.49 1.49 0 0 1 .153-1.539L9.747 8.17zm-.176-1.49-.011.002.01-.001Zm-.011.002A5.28 5.28 0 0 0 4.305 4.02zM4.305 4.02A5.28 5.28 0 0 0 3 7.5l1.306-3.48Z"/></svg>`,
};
export const iconPin24: {
  name: 'pin-24';
  data: string;
} = {
  name: 'pin-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M13.845 20.988a25 25 0 0 0 2.33-2.337c1.59-1.828 2.71-3.715 3.328-5.581-.617 1.866-1.739 3.753-3.328 5.58a25 25 0 0 1-2.56 2.538zm5.401 1.027a.56.56 0 0 1-.496.297H5.25a.563.563 0 0 1-.563-.562.563.563 0 0 0 .563.563h13.5a.56.56 0 0 0 .496-.298m-8.86-.828a25 25 0 0 1-2.56-2.536c-2.544-2.925-3.888-6.003-3.888-8.901 0 2.898 1.344 5.976 3.887 8.9a25 25 0 0 0 2.56 2.538Zm-2.711-2.405q1.021 1.17 2.179 2.206H5.25a.763.763 0 0 0 0 1.525h13.5a.763.763 0 0 0 0-1.526h-4.604q1.158-1.035 2.18-2.205c2.564-2.949 3.936-6.069 3.936-9.032a8.263 8.263 0 1 0-16.525 0c0 2.96 1.373 6.083 3.938 9.032M5.262 9.75a6.737 6.737 0 1 1 13.476 0c0 2.73-1.353 5.24-2.9 7.215-1.46 1.864-3.078 3.232-3.838 3.83-.76-.598-2.378-1.966-3.839-3.83C6.615 14.99 5.262 12.48 5.262 9.75m9.399 2.661A3.762 3.762 0 1 0 9.34 7.09a3.762 3.762 0 0 0 5.32 5.32Zm-4.243-1.079a2.238 2.238 0 1 1 3.164-3.164 2.238 2.238 0 0 1-3.164 3.164m4.99-.546a3.563 3.563 0 0 1-6.902-1.731 3.563 3.563 0 0 0 6.902 1.731m-5.722-1.8"/></svg>`,
};
export const iconPlus24: {
  name: 'plus-24';
  data: string;
} = {
  name: 'plus-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3a.75.75 0 0 1 .75.75v7.5h7.5a.75.75 0 0 1 0 1.5h-7.5v7.5a.75.75 0 0 1-1.5 0v-7.5h-7.5a.75.75 0 0 1 0-1.5h7.5v-7.5A.75.75 0 0 1 12 3"/></svg>`,
};
export const iconQuestion24: {
  name: 'question-24';
  data: string;
} = {
  name: 'question-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12m6-1.875C8.25 8.17 10.026 6.75 12 6.75s3.75 1.42 3.75 3.375c0 1.703-1.348 3-3 3.306v.069a.75.75 0 0 1-1.5 0v-.75A.75.75 0 0 1 12 12c1.34 0 2.25-.93 2.25-1.875 0-.944-.91-1.875-2.25-1.875s-2.25.93-2.25 1.875v.375a.75.75 0 0 1-1.5 0zM12 16.25a.75.75 0 0 1 .75.75v.017a.75.75 0 0 1-1.5 0V17a.75.75 0 0 1 .75-.75"/></svg>`,
};
export const iconSearch24: {
  name: 'search-24';
  data: string;
} = {
  name: 'search-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5M2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 0 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5"/></svg>`,
};
export const iconSettings24: {
  name: 'settings-24';
  data: string;
} = {
  name: 'settings-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M9.39 2.215a.75.75 0 0 1 .66.139l1.75 1.398q.202-.004.405 0l1.75-1.397a.75.75 0 0 1 .66-.139c.862.229 1.69.571 2.461 1.018a.75.75 0 0 1 .37.565l.25 2.226q.147.14.287.287l2.224.25a.75.75 0 0 1 .565.368c.448.772.791 1.6 1.02 2.461a.75.75 0 0 1-.139.661l-1.398 1.75q.006.202 0 .405l1.398 1.75c.148.186.2.43.139.66a10 10 0 0 1-1.013 2.46.75.75 0 0 1-.566.372l-2.226.25a8 8 0 0 1-.286.286l-.25 2.225a.75.75 0 0 1-.37.565c-.77.448-1.598.79-2.46 1.02a.75.75 0 0 1-.661-.139l-1.75-1.398q-.202.005-.405 0l-1.75 1.397a.75.75 0 0 1-.66.14 10 10 0 0 1-2.46-1.013.75.75 0 0 1-.372-.567l-.25-2.225a8 8 0 0 1-.288-.288l-2.228-.261a.75.75 0 0 1-.562-.368 10 10 0 0 1-1.02-2.462.75.75 0 0 1 .14-.66l1.397-1.75a8 8 0 0 1 0-.406l-1.397-1.75a.75.75 0 0 1-.139-.66c.229-.862.571-1.69 1.018-2.461a.75.75 0 0 1 .565-.37l2.226-.25a9 9 0 0 1 .287-.287l.25-2.224a.75.75 0 0 1 .37-.566c.77-.445 1.597-.785 2.458-1.012M8.009 4.347 7.77 6.463a.75.75 0 0 1-.248.478q-.305.27-.575.575a.75.75 0 0 1-.477.247l-2.118.238a8.6 8.6 0 0 0-.584 1.412l1.33 1.665a.75.75 0 0 1 .163.513 7 7 0 0 0 0 .815.75.75 0 0 1-.163.512l-1.33 1.666q.229.732.585 1.412l2.122.248a.75.75 0 0 1 .474.247q.27.306.575.576a.75.75 0 0 1 .248.477l.238 2.117q.68.354 1.41.58l1.667-1.33a.75.75 0 0 1 .512-.163q.407.025.815 0a.75.75 0 0 1 .513.163l1.665 1.33a8.5 8.5 0 0 0 1.41-.584l.238-2.118a.75.75 0 0 1 .248-.477q.305-.27.575-.575a.75.75 0 0 1 .477-.248l2.118-.238q.353-.68.58-1.41l-1.33-1.666a.75.75 0 0 1-.163-.513 7 7 0 0 0 0-.815.75.75 0 0 1 .162-.512l1.331-1.666a8.6 8.6 0 0 0-.585-1.41l-2.117-.238a.75.75 0 0 1-.478-.248 7 7 0 0 0-.575-.575.75.75 0 0 1-.247-.477l-.238-2.118a8.6 8.6 0 0 0-1.412-.584l-1.665 1.33a.75.75 0 0 1-.513.163 7 7 0 0 0-.815 0 .75.75 0 0 1-.512-.163L9.416 3.768q-.73.226-1.407.579M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6m-4.5 3a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0"/></svg>`,
};
export const iconTime24: {
  name: 'time-24';
  data: string;
} = {
  name: 'time-24',
  data: `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9.75.75a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5zM12 5.25a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15m-9 7.5a9 9 0 1 1 18 0 9 9 0 0 1-18 0m13.28-4.28a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l3.75-3.75a.75.75 0 0 1 1.06 0" fill="#01194E"/></svg>`,
};
export const iconUser24: {
  name: 'user-24';
  data: string;
} = {
  name: 'user-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12 3.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5m3.458 11.048a6.75 6.75 0 1 0-6.916 0c-2.638.866-4.816 2.7-6.191 5.076a.75.75 0 0 0 1.298.752C5.341 17.703 8.4 15.75 12 15.75s6.66 1.953 8.35 4.876a.75.75 0 1 0 1.3-.752c-1.377-2.377-3.554-4.21-6.192-5.076"/></svg>`,
};
export const iconWarning24: {
  name: 'warning-24';
  data: string;
} = {
  name: 'warning-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M12.7 4.144c-.301-.525-1.098-.525-1.4 0L3.1 18.381c-.271.475.061 1.119.7 1.119h16.4c.639 0 .971-.643.7-1.118zm1.301-.747c-.879-1.53-3.123-1.53-4.002 0L1.8 17.633v.002C.922 19.166 2.074 21 3.8 21h16.398c1.727 0 2.88-1.833 2.002-3.364zM12 9a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9.75A.75.75 0 0 1 12 9m-.75 7.875a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75"/></svg>`,
};
export const iconX24: {
  name: 'x-24';
  data: string;
} = {
  name: 'x-24',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill-rule="evenodd" d="M4.72 4.72a.75.75 0 0 1 1.06 0L12 10.94l6.22-6.22a.75.75 0 1 1 1.06 1.06L13.06 12l6.22 6.22a.75.75 0 1 1-1.06 1.06L12 13.06l-6.22 6.22a.75.75 0 0 1-1.06-1.06L10.94 12 4.72 5.78a.75.75 0 0 1 0-1.06"/></svg>`,
};
export type LumIcon =
  | 'address-book-16'
  | 'alternative-16'
  | 'arrow-down-16'
  | 'arrow-left-16'
  | 'arrow-right-16'
  | 'arrow-up-16'
  | 'briefcase-16'
  | 'calendar-16'
  | 'caret-down-16'
  | 'caret-up-16'
  | 'check-16'
  | 'check-circle-16'
  | 'copy-16'
  | 'dashboard-16'
  | 'decline-16'
  | 'delete-16'
  | 'download-16'
  | 'edit-16'
  | 'error-16'
  | 'external-link-16'
  | 'generate-16'
  | 'handshake-16'
  | 'hard-drive-16'
  | 'info-16'
  | 'mail-16'
  | 'menu-01-16'
  | 'menu-02-16'
  | 'menu-03-16'
  | 'minus-16'
  | 'news-16'
  | 'notification-16'
  | 'pending-16'
  | 'phone-16'
  | 'plus-16'
  | 'question-16'
  | 'search-16'
  | 'settings-16'
  | 'sorting-ascending-16'
  | 'sorting-decending-16'
  | 'sorting-inactive-16'
  | 'user-16'
  | 'warning-16'
  | 'x-16'
  | 'address-book-24'
  | 'alternative-24'
  | 'arrow-down-24'
  | 'arrow-left-24'
  | 'arrow-right-24'
  | 'arrow-up-24'
  | 'briefcase-24'
  | 'calendar-24'
  | 'caret-down-24'
  | 'caret-up-24'
  | 'category-24'
  | 'check-24'
  | 'check-circle-24'
  | 'copy-24'
  | 'dashboard-24'
  | 'delete-24'
  | 'delivery-24'
  | 'deliverydate-24'
  | 'detail-24'
  | 'developer-24'
  | 'edit-24'
  | 'error-24'
  | 'generate-24'
  | 'handshake-24'
  | 'hard-drive-24'
  | 'id-24'
  | 'info-24'
  | 'lumeso-buildup-24'
  | 'lumeso-buildup-alert-24'
  | 'lumeso-poduct-alert-24'
  | 'lumeso-processing-24'
  | 'lumeso-processing-alert-24'
  | 'lumeso-product-24'
  | 'mail-24'
  | 'menu-01-24'
  | 'menu-02-24'
  | 'menu-03-24'
  | 'minus-24'
  | 'my-buildup-24'
  | 'my-buildup-alert-24'
  | 'my-processing-24'
  | 'my-processing-alert-24'
  | 'my-product-24'
  | 'my-product-alert-24'
  | 'news-24'
  | 'notification-24'
  | 'pending-24'
  | 'phone-24'
  | 'pin-24'
  | 'plus-24'
  | 'question-24'
  | 'search-24'
  | 'settings-24'
  | 'time-24'
  | 'user-24'
  | 'warning-24'
  | 'x-24';
export interface LumIconInterface {
  name: LumIcon;
  data: string;
}
export type LumIconInterfaceNameSubset<T extends Readonly<LumIconInterface[]>> =
  T[number]['name'];
export const completeIconSet = [
  iconAddressBook16,
  iconAlternative16,
  iconArrowDown16,
  iconArrowLeft16,
  iconArrowRight16,
  iconArrowUp16,
  iconBriefcase16,
  iconCalendar16,
  iconCaretDown16,
  iconCaretUp16,
  iconCheck16,
  iconCheckCircle16,
  iconCopy16,
  iconDashboard16,
  iconDecline16,
  iconDelete16,
  iconDownload16,
  iconEdit16,
  iconError16,
  iconExternalLink16,
  iconGenerate16,
  iconHandshake16,
  iconHardDrive16,
  iconInfo16,
  iconMail16,
  iconMenu0116,
  iconMenu0216,
  iconMenu0316,
  iconMinus16,
  iconNews16,
  iconNotification16,
  iconPending16,
  iconPhone16,
  iconPlus16,
  iconQuestion16,
  iconSearch16,
  iconSettings16,
  iconSortingAscending16,
  iconSortingDecending16,
  iconSortingInactive16,
  iconUser16,
  iconWarning16,
  iconX16,
  iconAddressBook24,
  iconAlternative24,
  iconArrowDown24,
  iconArrowLeft24,
  iconArrowRight24,
  iconArrowUp24,
  iconBriefcase24,
  iconCalendar24,
  iconCaretDown24,
  iconCaretUp24,
  iconCategory24,
  iconCheck24,
  iconCheckCircle24,
  iconCopy24,
  iconDashboard24,
  iconDelete24,
  iconDelivery24,
  iconDeliverydate24,
  iconDetail24,
  iconDeveloper24,
  iconEdit24,
  iconError24,
  iconGenerate24,
  iconHandshake24,
  iconHardDrive24,
  iconId24,
  iconInfo24,
  iconLumesoBuildup24,
  iconLumesoBuildupAlert24,
  iconLumesoPoductAlert24,
  iconLumesoProcessing24,
  iconLumesoProcessingAlert24,
  iconLumesoProduct24,
  iconMail24,
  iconMenu0124,
  iconMenu0224,
  iconMenu0324,
  iconMinus24,
  iconMyBuildup24,
  iconMyBuildupAlert24,
  iconMyProcessing24,
  iconMyProcessingAlert24,
  iconMyProduct24,
  iconMyProductAlert24,
  iconNews24,
  iconNotification24,
  iconPending24,
  iconPhone24,
  iconPin24,
  iconPlus24,
  iconQuestion24,
  iconSearch24,
  iconSettings24,
  iconTime24,
  iconUser24,
  iconWarning24,
  iconX24,
];
