import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../../ui/icon/icon.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'lum-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        IconComponent,
        TranslatePipe,
    ]
})
export class ErrorComponent implements OnInit {
  @Input() errors?: ValidationErrors | null;
  @Input() isIconVisible = true;

  constructor() {}

  ngOnInit(): void {}
}
