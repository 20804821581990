import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { SpinnerComponent } from '@lum-ui/spinner';
import { TranslatePipe } from '@ngx-translate/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { LumIcon } from '../../types/_icon.type';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'lum-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, IconComponent, TranslatePipe, SpinnerComponent],
})
export class ToastMessageComponent extends Toast implements OnDestroy {
  public type?: string;
  public iconName?: LumIcon;
  public isProgressToast?: boolean;

  constructor(
    public override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);

    this.isProgressToast =
      this.options.progressBar &&
      typeof this.options.disableTimeOut === 'boolean' &&
      this.options.disableTimeOut;
    this.type = this.toastPackage?.toastType;
    this.setIconName();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onRemoveClick(): void {
    this.remove();
  }

  private setIconName(): void {
    switch (this.type) {
      case 'toast-error':
        this.iconName = 'error-24';
        break;

      case 'toast-info':
        this.iconName = 'info-24';
        break;

      case 'toast-success':
        this.iconName = 'check-circle-24';
        break;

      case 'toast-warning':
        this.iconName = 'warning-24';
        break;
    }
  }
}
