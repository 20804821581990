import { Inject, Injectable } from '@angular/core';
import { ListItem, SortDirection, SortingProjection } from '@dcupl/common';
import { DcuplConnect } from '@dcupl/connect';
import { Dcupl, DcuplList } from '@dcupl/core';
import { AppLoaderConfiguration, DcuplAppLoader } from '@dcupl/loader';
import {
  LUM_ENVIRONMENT,
  LumAssignment,
  LumEnvironmentInterface,
  LumPlatformProduct,
} from '@lum-types';
import { compact, first, first as firstValue } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { LumLogger } from '../utils/logger.utils';
import { LumRequestParams } from './api/api.service';
import { AuthService } from './auth.service';

export type LumDcuplStates = 'initializing' | 'initialized' | 'failed';

@Injectable({
  providedIn: 'root',
})
export class DcuplService {
  public dcupl = new Dcupl({
    config: {
      projectId: 'bJ1VmyubetaIMCGPowI5',
    },
    referenceMetadata: {
      enabled: true,
    },
  });
  public dcuplLoader?: DcuplAppLoader;
  public isDcuplInitializing$ = new BehaviorSubject<LumDcuplStates>(
    'initializing'
  );
  private activeOptions?: AppLoaderConfiguration.ProcessOptions;

  constructor(
    @Inject(LUM_ENVIRONMENT)
    public readonly environment: LumEnvironmentInterface,
    private readonly authService: AuthService
  ) {}

  public async initDcupl(): Promise<void> {
    LumLogger.time('dcupl - init');
    this.isDcuplInitializing$.next('initializing');

    const connectClient = new DcuplConnect({
      dcuplInstance: this.dcupl,
    });
    await connectClient.init();

    // 1) create your loader, add the loader to your core and fetch the config
    this.dcuplLoader = new DcuplAppLoader();
    this.dcupl.loaders.add(this.dcuplLoader);

    // set auth token
    try {
      const token = await this.authService.getAuthToken();
      this.dcuplLoader?.variables.global.set('authToken', token);

      this.dcuplLoader?.variables.global.set(
        'backendBaseUrl',
        this.environment.api.baseUrl
      );

      this.dcuplLoader?.variables.global.set(
        'environment',
        ['local', 'testing'].includes(this.environment.env)
          ? 'dev'
          : this.environment.env
      );

      try {
        if (this.environment.dcupl?.baseUrl) {
          await this.dcuplLoader.config.fetch({
            baseUrl: this.environment.dcupl.baseUrl,
            loaderFileName: 'dcupl.lc.json',
            headers: [{ key: 'Authorization', value: `Bearer ${token}` }],
          });
        } else {
          await this.dcuplLoader.config.fetch(); // will get the data from our CDN
        }
      } catch (error) {
        LumLogger.error('Error while fetching dcupl config', error);
        this.isDcuplInitializing$.next('failed');
        return;
      }

      this.activeOptions = {
        applicationKey:
          this.environment.dcupl?.applicationKey ?? 'connect-lumeso',
        environmentKeys: ['web-application'],
      };

      await this.dcuplLoader.process(this.activeOptions);

      // 3) init dcupl - models and data will be combined
      await this.dcupl.init();
      this.isDcuplInitializing$.next('initialized');
      LumLogger.timeEnd('dcupl - init');
    } catch (error) {
      LumLogger.error('error while initializing dcupl', error);
      this.isDcuplInitializing$.next('failed');
    }
  }

  public async reprocessRessources(resourceTag: string): Promise<void> {
    const token = await this.authService.getAuthToken();
    this.dcuplLoader?.variables.global.set('authToken', token);

    if (this.activeOptions) {
      const options: AppLoaderConfiguration.ProcessOptions = Object.assign(
        this.activeOptions,
        {
          resourceTags: [resourceTag],
        }
      );

      LumLogger.time(`${resourceTag} - request`);
      await this.dcuplLoader?.process(options);
      LumLogger.timeEnd(`${resourceTag} - request`);

      LumLogger.time(`${resourceTag} - processing`);
      await this.dcupl.update();
      LumLogger.timeEnd(`${resourceTag} - processing`);
    }
  }

  public getSortParam<T>(
    queryParams: LumRequestParams
  ): SortingProjection<T & ListItem> {
    const sort: SortingProjection<T & ListItem> = {
      attributes: [] as string[],
      order: [] as SortDirection[],
    };

    const querySort = queryParams['sort'];
    if (querySort) {
      sort.attributes = [querySort.substring(1)];
      sort.order = [firstValue(querySort) === '-' ? 'DESC' : 'ASC'];
    }

    return sort;
  }

  public getPaginationParam(queryParams: LumRequestParams): {
    start: number;
    count: number;
  } {
    const pageNumber = Number(queryParams['pageNumber']);
    const pageSize = Number(queryParams['pageSize']);
    return {
      count: pageSize,
      start: pageSize * pageNumber,
    };
  }

  public applyFilterParam<T>(
    queryParams: LumRequestParams,
    dcuplList?: DcuplList<T>
  ): void {
    if (!dcuplList) {
      return;
    }

    Object.entries(queryParams).forEach(([key, value]) => {
      if (key.startsWith('filter')) {
        const [, propertyName] = key.split('[');
        const [groupKey] = propertyName.split(']');
        this.applyDcuplQuery<T>(dcuplList, groupKey as keyof T, value);
      }
    });
  }

  public applyDcuplQuery<T>(
    dcuplList: DcuplList<T>,
    key: keyof T,
    value?: string
  ): void {
    if (!value) {
      dcuplList?.catalog.query.remove({
        groupKey: key as string,
      });
      return;
    }

    let queryValue: unknown = value;
    if (['hit', 'miss'].includes(value)) {
      queryValue = { _dcupl_ref_: value };
    } else if (value === 'undefined') {
      queryValue = undefined;
    } else {
      queryValue = `/${value}/`;
    }

    dcuplList?.catalog.query.apply(
      {
        groupKey: key as string,
        groupType: 'or',
        queries: [
          {
            queryKey: value,
            attribute: key,
            value: queryValue,
            operator: queryValue ? 'find' : 'isTruthy',
            options: {
              transform: ['lowercase'],
            },
          },
        ],
      },
      {
        mode: 'set',
      }
    );
  }

  public async getProductByKeyAndModelKey(
    productKey: string,
    modelKey: string
  ): Promise<LumPlatformProduct | undefined> {
    const result = this.dcupl.query.execute<LumPlatformProduct>({
      modelKey,
      groupKey: 'root',
      groupType: 'and',
      queries: [
        {
          groupKey: 'id',
          groupType: 'or',
          queries: [
            {
              queryKey: productKey,
              attribute: 'id',
              value: `/${productKey}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
      ],
    });

    return first(compact(result));
  }

  public async getAssignmentByKeyAndModelKey(
    assignmentKey: string,
    modelKey: string
  ): Promise<LumAssignment | undefined> {
    const result = this.dcupl.query.execute({
      modelKey,
      groupKey: 'root',
      groupType: 'and',
      queries: [
        {
          groupKey: 'id',
          groupType: 'or',
          queries: [
            {
              queryKey: assignmentKey,
              attribute: 'id',
              value: `/${assignmentKey}/`,
              operator: 'find',
              options: {
                transform: ['lowercase'],
              },
            },
          ],
        },
      ],
    });

    return first(compact(result)) as LumAssignment | undefined;
  }
}
