<lum-dialog>
  <ng-container id="header">
    <div class="flex gap-2">
      <lum-icon
        *ngIf="data.headlineHighlight; let highlight"
        [name]="highlight.iconName"
        [ngClass]="highlight.color"
      ></lum-icon>
      {{ data.headline | translate }}
    </div>
  </ng-container>

  <p [innerHTML]="data.content | translate: data.translateOptions"></p>

  <ng-container id="footer">
    <div class="flex items-center justify-center gap-2">
      <button
        lum-button
        variant="secondary"
        type="button"
        (click)="onCloseClick()"
        data-cy="btn_cancel"
      >
        {{ 'lum.action.cancel' | translate }}
      </button>
      <button
        lum-button
        type="button"
        (click)="onCloseClick(true)"
        data-cy="btn_confirm"
      >
        {{ data.cta ?? 'lum.action.confirm' | translate }}
      </button>
    </div>
    <!-- id is needed in order to store information in local storage -->
    @if (data.neverShowAgain && data.id) {
      <div class="flex items-center justify-center mt-6">
        <lum-form-field
          [control]="neverShowAgainControl"
          [formField]="formField"
        ></lum-form-field>
      </div>
    }
  </ng-container>
</lum-dialog>
