import { Injectable } from '@angular/core';
import { LumUser } from '@lum-types';
import { Observable } from 'rxjs';
import { DcuplService } from '../../dcupl.service';
import { ApiService } from '../api.service';
import { BaseDataAPIService } from '../base-data/base-data.api.service';
import { UserService } from './user.abstract';

@Injectable({
  providedIn: 'root',
})
export class UserAPIService
  extends BaseDataAPIService<LumUser>
  implements UserService
{
  constructor(
    public override apiService: ApiService,
    public override dcuplService: DcuplService
  ) {
    super(apiService, dcuplService);
    this._baseApiRoute = 'user';
  }

  public getCurrentUser(): Observable<LumUser> {
    return this.apiService.get<LumUser>({
      endpoint: this._baseApiRoute,
      path: 'current',
    });
  }
}
