import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  LumFormFieldExtComponent,
  LumFormValue,
  LumSelectOption,
} from '@lum-form';
import { NgClass } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { IconComponent } from '../../../ui/icon/icon.component';

@Component({
    selector: 'lum-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [
        NgClass,
        MatSelect,
        ReactiveFormsModule,
        MatOption,
        IconComponent,
    ]
})
export class SelectComponent<
  OptionKey extends LumFormValue,
> extends LumFormFieldExtComponent {
  @Input({ required: true }) options!: LumSelectOption<OptionKey>[];
  @Input() placeholder?: string;
  @Input() isDisabled? = false;

  public isExpanded = false;

  constructor() {
    super();
  }
}
